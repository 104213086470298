import PhotoIcon from '@mui/icons-material/Photo';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import type {
  GridRenderCellParams,
  GridRowId,
  GridRowIdGetter,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import React from 'react';
import { getSignedUrl, SignedImage } from '@components/Common/SignedImage';
import type { GeoPositionSolution, Photo } from '@types';
import { getGeolocationColor } from '@utils';
import styles from './styles.module.css';

export const getRowId: GridRowIdGetter<GridValidRowModel> = (row): GridRowId => {
  return (row as Photo).id;
};

export const renderAsDate = (recordedDate?: string) => {
  if (!recordedDate) return null;

  return DateTime.fromISO(recordedDate).setLocale('en-gb').toLocaleString();
};

const getGeolocationToolTip = (geoPositionSolution?: GeoPositionSolution): string => {
  switch (geoPositionSolution) {
    case 'CORELOCATION':
      return 'meter';
    case 'FLOAT':
      return 'decimeter';
    case 'FIXED':
      return 'centimeter';
    default:
      return 'Unknown';
  }
};

export const renderAGgeoPositionSolution = ({ value }: GridRenderCellParams<Photo, Photo>) => (
  <Tooltip
    arrow
    placement="right"
    title={<div>{getGeolocationToolTip(value?.geoPositionSolution)}</div>}
  >
    <Box
      component="div"
      sx={(theme) => ({
        backgroundColor: getGeolocationColor(value?.geoPositionSolution),
        borderRadius: theme.shape.borderRadius,
        height: theme.spacing(3),
        width: theme.spacing(3),
      })}
    ></Box>
  </Tooltip>
);

const onImageButtonClick = async (url?: string | null) => {
  const imageSrc = await getSignedUrl(url);

  window.open(imageSrc, '_blank');
};

const tooltipClasses = { tooltip: styles.tooltip };

export const renderAsPhoto = ({
  row: { url, urlPreview, urlThumbnail },
}: GridRenderCellParams<Photo, Photo>) => {
  const previewUrl = urlPreview || url;

  return (
    <Tooltip
      arrow
      classes={tooltipClasses}
      placement="right"
      title={
        <SignedImage
          alt="preview"
          height="500px"
          src={previewUrl ?? '/no_image_available.png'}
          style={{ border: '6px solid grey' }}
          width="auto"
        />
      }
    >
      <Button
        onClick={() => onImageButtonClick(url)}
        sx={{
          height: 60,
        }}
        variant="text"
      >
        {urlThumbnail ? (
          <SignedImage alt="thumbnail" originalSize={true} src={urlThumbnail ?? undefined} />
        ) : (
          <PhotoIcon />
        )}
      </Button>
    </Tooltip>
  );
};

export const renderMultilineText = (text?: string | null) => {
  if (!text) return null;

  return (
    <Typography variant="body2" whiteSpace="normal">
      {text}
    </Typography>
  );
};
