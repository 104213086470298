import { proxy, useSnapshot } from 'valtio';

interface HeaderState {
  title: string;
  showBackButton: boolean;
}

const headerState = proxy<HeaderState>({ title: 'Cockpit', showBackButton: false });

function setProjectTitle(projectTitle: string) {
  headerState.title = projectTitle;
  headerState.showBackButton = true;
}

function clearProjectTitle() {
  headerState.title = 'Cockpit';
  headerState.showBackButton = false;
}

export function useHeaderState() {
  const { title, showBackButton } = useSnapshot(headerState);

  return {
    title,
    showBackButton,
    setProjectTitle,
    clearProjectTitle,
  };
}
