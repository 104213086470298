import { Dialog } from '@mui/material';
import type { FC } from 'react';
import React from 'react';
import { FloatingCloseBtn } from './FloatingCloseButton';

export const FullscreenMobileOverlay: FC<{
  onClose: () => void;
  open: boolean;
  children: React.ReactNode;
}> = ({ onClose, children, open }) => (
  <Dialog fullScreen onClose={onClose} open={open}>
    <FloatingCloseBtn onClick={onClose} />
    {children}
  </Dialog>
);
