import axios, { AxiosError, AxiosRequestConfig, Method, ResponseType } from 'axios';
import { DateTime } from 'luxon';
import {
  elementTypeUsages,
  layingTypeColors,
  layingTypes,
  surfaceTypeColors,
  surfaceTypes,
  usageTypeColors,
} from '@types';

type StringBoolNumber = string | boolean | number | undefined;
export type QueryParameters = Record<string, StringBoolNumber>;

export interface ApiOptions<Body = undefined> {
  body?: Body;
  id?: string;
  params?: QueryParameters;
  path: string;
  responseType?: ResponseType;
}

let baseUrl = '';

let getAccessToken = () => Promise.resolve('');

export const setup = (options: {
  baseUrl: string;
  getAccessToken: () => Promise<string>;
}): void => {
  baseUrl = options.baseUrl;
  getAccessToken = options.getAccessToken;
};

export const request = async <Response, Body = undefined, RawResponse = Response>(
  httpMethod: Method,
  options: ApiOptions<Body>,
  mapResponse: (res: RawResponse) => Response = (res: RawResponse) => res as unknown as Response,
): Promise<Response> => {
  const { path, body, params, responseType } = options;
  const config: AxiosRequestConfig = {
    method: httpMethod,
    url: `${baseUrl}${path}`,
    headers: { Authorization: `Bearer ${await getAccessToken()}` },
    responseType: responseType || 'json',
    data: body,
    params,
  };

  try {
    const res = await axios.request<RawResponse>(config);

    return mapResponse(res.data);
  } catch (err) {
    const detail = (err as AxiosError).response?.data?.detail;

    if (detail) alert(detail);

    throw err;
  }
};

// taken from https://stackoverflow.com/questions/3749231/download-file-using-javascript-jquery
// chosen a js native approach because it's easier to implement and doesn't require an external react library or a custom wrapper
export const downloadBlob = (() => {
  const a = document.createElement('a');

  document.body.appendChild(a);

  return (data: Blob, fileName: string) => {
    const url = window.URL.createObjectURL(data);

    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();

const getTimeComparisonExpression = (fromMillis: number, toMillis: number) => {
  const featureTimeStamp = ['get', 'timestamp'];

  return ['all', ['>=', fromMillis, featureTimeStamp], ['<=', toMillis, featureTimeStamp]];
};

const isToday = () => {
  const today = DateTime.now();
  const yesterday = today.minus({ days: 1 });

  return getTimeComparisonExpression(today.toMillis(), yesterday.toMillis());
};

const isYesterday = () => {
  const yesterday = DateTime.now().minus({ days: 1 });
  const dayBeforeYesterday = yesterday.minus({ days: 2 });

  return getTimeComparisonExpression(yesterday.toMillis(), dayBeforeYesterday.toMillis());
};

const isDayBeforeYesterday = () => {
  const dayBeforeYesterday = DateTime.now().minus({ days: 2 });
  const dayBeforeBeforeYesterday = dayBeforeYesterday.minus({ days: 3 });

  return getTimeComparisonExpression(
    dayBeforeYesterday.toMillis(),
    dayBeforeBeforeYesterday.toMillis(),
  );
};

const defaultOpacity = 1;
const todayOpacity = 0.5;
const yesterdayOpacity = 0.6;
const dayBeforeYesterdayOpacity = 0.7;

const getLineOpacityExpression = () => [
  'case',
  isToday(),
  todayOpacity,
  isYesterday(),
  yesterdayOpacity,
  isDayBeforeYesterday(),
  dayBeforeYesterdayOpacity,
  defaultOpacity,
];

export const buildStyleLayersUsage = (
  globalFilterExpressions: string[],
  shouldRenderOther: boolean,
) => {
  const defaultStyles = elementTypeUsages.map((u) => ({
    id: u,
    type: 'line' as const,
    filter: ['all', ['in', u, ['get', 'usageTypes']], ...globalFilterExpressions],
    paint: {
      'line-width': 4, // possible with element.diameter between a normalized range
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#cdcdcd',
        usageTypeColors[u],
      ],
      'line-opacity': getLineOpacityExpression(),
    },
  }));

  if (!shouldRenderOther) {
    return defaultStyles;
  }

  return [
    ...defaultStyles,
    {
      id: 'OTHER',
      type: 'line' as const,
      filter: ['all', ['in', 'OTHER', ['get', 'usageTypes']], ...globalFilterExpressions],
      paint: {
        'line-width': 4, // possible with element.diameter between a normalized range
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          '#cdcdcd',
          '#000000',
        ],
        'line-opacity': getLineOpacityExpression(),
      },
    },
  ];
};
export const buildStyleLayersSurface = (globalFilterExpressions: string[]) =>
  surfaceTypes.map((u) => ({
    id: u,
    type: 'line' as const,
    filter: [
      'all',
      ['!=', ['get', 'surfaceType'], null],
      ['==', ['get', 'surfaceType'], u],
      ...globalFilterExpressions,
    ],
    paint: {
      'line-width': 4, // possible with element.diameter between a normalized range
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#cdcdcd',
        surfaceTypeColors[u],
      ],
      'line-opacity': getLineOpacityExpression(),
    },
  }));

export const buildStyleLayersLaying = (globalFilterExpressions: string[]) =>
  layingTypes.map((u) => ({
    id: u,
    type: 'line' as const,
    filter: [
      'all',
      ['==', ['get', 'layingType'], u === 'OPEN_CONSTRUCTION' ? null : u],
      ...globalFilterExpressions,
    ],
    paint: {
      'line-width': 4, // possible with element.diameter between a normalized range
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#cdcdcd',
        layingTypeColors[u],
      ],
      'line-opacity': getLineOpacityExpression(),
    },
  }));

const buildGlobalFilters = (
  fromDate: number,
  toDate: number,
  selectedScanDevices: string[],
  // Mapbox filter property is typed with any[], therefore we do it too
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any[] => [
  ['in', ['get', 'scanDeviceName'], ['literal', selectedScanDevices]],
  ['<=', fromDate, ['get', 'timestamp']],
  ['>=', toDate, ['get', 'timestamp']],
];

export const buildStyleLayers = (
  fromDate: number,
  toDate: number,
  selectedScanDevices: string[],
  shouldRenderOther: boolean,
) => {
  const globalFilters = buildGlobalFilters(fromDate, toDate, selectedScanDevices);

  return [
    ...buildStyleLayersUsage(globalFilters, shouldRenderOther),
    ...buildStyleLayersSurface(globalFilters),
    ...buildStyleLayersLaying(globalFilters),
  ];
};
