import { DateTime } from 'luxon';
import ReactGA from 'react-ga4';

import { filterState } from '@state/Filter';
import { loggedInUserState } from '@state/LoggedInUser';
import { calcDayRange } from '@utils';

export const locationChanged = (url: string): void => {
  ReactGA.set({ page: url });
  ReactGA.send('pageview');
};

export const trackDownloadPhotos = (count: number): void => {
  sendAnalytics('DownloadPhotos', { count });
};

export const trackDownloadTimePhotos = (ms: number): void => {
  sendAnalytics('DownloadTimePhotos', { seconds: Math.round(ms / 1000) });
};

export const trackDownloadSuccess = (wasSuccessful: boolean): void => {
  sendAnalytics('DownloadStatus', { success: wasSuccessful });
};

export const trackFilterDateChanged = (): void => {
  const { fromDate, toDate } = filterState;
  const now = DateTime.now();

  const payload = {
    numberDaysNowToDate: calcDayRange(now, toDate),
    numberDaysNowFromDate: calcDayRange(now, fromDate),
    numberDaysRange: calcDayRange(toDate, fromDate),
  };

  sendAnalytics('FilterDateChanged', payload);
};

export const trackPhotoCategoryChanged = (category: string): void => {
  sendAnalytics('PhotoCategoryChanged', { category });
};

export const trackShowTrenchTopdownViews = (): void => {
  sendAnalytics('ShowTrenchTopdownViews');
};

type AnalyticsEvent =
  | 'DownloadPhotos'
  | 'DownloadTimePhotos'
  | 'DownloadStatus'
  | 'PhotoCategoryChanged'
  | 'FilterDateChanged'
  | 'ShowTrenchTopdownViews';

export type PossibleUserEventPayloads =
  | { seconds: number }
  | { count: number }
  | { success: boolean }
  | { category: string }
  | {
      numberDaysNowToDate: number;
      numberDaysNowFromDate: number;
      numberDaysRange: number;
    };

const sendAnalytics = (event: AnalyticsEvent, payload?: PossibleUserEventPayloads) => {
  ReactGA.event(event, { domain: loggedInUserState.domain, ...payload });
};
