import { useParams } from 'react-router-dom';

// this is a whitelist for projectIds which should display OTHER elements. TODO [https://deepup-gmbh.atlassian.net/browse/DP-2688] this is a whitelist workaround for projectIds which should display OTHER elements. Remove asap!
const workaroundOtherProjectIds = [
  'a6327a7d-e44d-4302-ad78-a311712497bd',
  '68da2fa9-438b-40b0-826e-cb8368d71138',
  '8caa0c73-f969-4a4f-acab-44290bb46acc',
];

export const useShouldProjectShowOther = () => {
  const { projectId } = useParams<{ projectId: string }>();

  return workaroundOtherProjectIds.includes(projectId || '');
};
