import type { LinePaint } from 'mapbox-gl';
import React, { type FC } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useSnapshot } from 'valtio';
import { buildStyleLayers, useShouldProjectShowOther } from '@api';
import { filterState } from '@state/Filter';
import { useProjectData } from '@state/ProjectData';
import type { ElementTypeUsage, LayingType, SurfaceType } from '@types';
import { getNamesOfSelectedDevices } from '../helper';

export const TrenchDataLayer: FC = React.memo(function TrenchDataLayer() {
  const trenchElementGeoJson = useProjectData((state) => state.trenchElementGeoJson);
  const { fromDate, toDate, visibleMapFeatures, selectedVisibilityTab, scanDevices } =
    useSnapshot(filterState);
  const selectedScanDevices = getNamesOfSelectedDevices(scanDevices);

  const shouldRenderOther = useShouldProjectShowOther();

  const layerStyles = buildStyleLayers(
    fromDate.startOf('day').toUnixInteger() * 1000,
    toDate.startOf('day').toUnixInteger() * 1000,
    selectedScanDevices,
    shouldRenderOther,
  );

  return (
    <Source data={trenchElementGeoJson} id="trench-data" type="geojson">
      {layerStyles.map((layer) => (
        <Layer
          filter={layer.filter}
          id={layer.id}
          key={layer.id}
          layout={{
            visibility:
              visibleMapFeatures[selectedVisibilityTab][
                layer.id as ElementTypeUsage & LayingType & SurfaceType
              ] ||
              (shouldRenderOther && layer.id === 'OTHER')
                ? 'visible'
                : 'none',
          }}
          paint={layer.paint as LinePaint}
          type={layer.type}
        />
      ))}
    </Source>
  );
});
