import type { DateTime } from 'luxon';
import type { LngLatBoundsLike } from 'mapbox-gl';
import type {
  ElementTypeUsage,
  LatLngBoundsNarrowType,
  Segment,
  TrenchElementGeoJson,
} from '@types';

const germanyMin = { lng: 5.98865807458, lat: 47.3024876979 };
const germanyMax = { lng: 15.0169958839, lat: 54.983104153 };

export const germanyBounds: LngLatBoundsLike = [germanyMin, germanyMax];

const defaultMin = { lng: 180, lat: 90 };
const defaultMax = { lng: -180, lat: -90 };

export const defaultBounds: LngLatBoundsLike = [defaultMin, defaultMax];

export const filterFeatures = (
  geojson: TrenchElementGeoJson,
  fromDate: DateTime,
  toDate: DateTime,
  visibilities: string[],
): Segment[] => {
  return geojson.features.filter((f) => {
    const { timestamp, usageTypes, surfaceType, layingType } = f.properties;

    return (
      fromDate.toMillis() <= timestamp &&
      timestamp <= toDate.toMillis() &&
      (usageTypes.some((u: ElementTypeUsage) => visibilities.includes(u)) ||
        visibilities.includes(surfaceType) ||
        visibilities.includes(layingType))
    );
  });
};

/* 
   Checks if the given lat/long coordinates 
   lie inside the given bounding box
*/
const areCoordinatesInsideBBox = (
  bbox: LatLngBoundsNarrowType,
  currentLng: number,
  currentLat: number,
): boolean => {
  const [initialMin, initalMax] = bbox;

  if (
    currentLng < initialMin.lng ||
    currentLng > initalMax.lng ||
    currentLat < initialMin.lat ||
    currentLat > initalMax.lat
  ) {
    return false;
  }

  return true;
};

export const getBounds = (
  geoJson: TrenchElementGeoJson,
  initialBounds?: LatLngBoundsNarrowType,
): LngLatBoundsLike => {
  if (!geoJson?.features) {
    return initialBounds ?? defaultBounds;
  }

  let minLng = defaultMin.lng;
  let minLat = defaultMin.lat;
  let maxLng = defaultMax.lng;
  let maxLat = defaultMax.lat;

  geoJson.features.forEach((feature) =>
    feature.geometry.coordinates.forEach(([currentLng, currentLat]) => {
      if (initialBounds && !areCoordinatesInsideBBox(initialBounds, currentLng, currentLat)) return;
      if (currentLng < minLng) minLng = currentLng;
      if (currentLng > maxLng) maxLng = currentLng;
      if (currentLat < minLat) minLat = currentLat;
      if (currentLat > maxLat) maxLat = currentLat;
    }),
  );

  return [
    { lng: minLng, lat: minLat },
    { lng: maxLng, lat: maxLat },
  ];
};
