import { ArrowsMaximize4, ArrowsMinimize4, XOutline } from '@deepup/icons';
import { useDeepUpTheme } from '@deepup/mui-theme-deepup';
import { Drawer, Box, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';

interface Props {
  children?: React.ReactNode;
  open: boolean;
  onClose: () => void;
  drawerWidth?: number | string;
  text?: React.ReactNode;
}

export const RightDrawer: React.FC<Props> = ({
  children,
  open,
  onClose: onCloseExternal,
  drawerWidth = 360,
  text,
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { theme } = useDeepUpTheme();

  const onFullscreen = () => {
    setIsFullscreen((prevState) => !prevState);
  };

  const onClose = () => {
    setIsFullscreen(false);
    onCloseExternal();
  };

  return (
    <Drawer
      anchor="right"
      hideBackdrop
      onClose={onClose}
      open={open}
      sx={{
        position: 'absolute',
        width: isFullscreen ? '100%' : drawerWidth,
        top: '200px',
        left: 'auto',
        [`& .MuiDrawer-paper`]: {
          width: isFullscreen ? '100%' : drawerWidth,
          boxSizing: 'border-box',
          top: '200px',
          height: 'calc(100% - 200px)',
        },
      }}
    >
      <Box
        component="div"
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
      >
        <Box
          component="div"
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            padding: '10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            component="div"
            sx={{
              display: 'flex',
              gap: '20px',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={onClose} size="small" sx={{ fontSize: '22px' }}>
              <XOutline fill={theme.palette.grey[500]} />
            </IconButton>
            <Typography variant="subtitle2">{text}</Typography>
          </Box>
          <IconButton onClick={onFullscreen} size="small" sx={{ fontSize: '18px' }}>
            {isFullscreen ? (
              <ArrowsMinimize4 fill={theme.palette.grey[500]} />
            ) : (
              <ArrowsMaximize4 fill={theme.palette.grey[500]} />
            )}
          </IconButton>
        </Box>
        <Box component="div" sx={{ flex: 1, overflowY: 'auto' }}>
          {children}
        </Box>
      </Box>
    </Drawer>
  );
};
