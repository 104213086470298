import { Box, CircularProgress } from '@mui/material';
import * as React from 'react';
import { request } from '@api';

export const getSignedUrl = async (url?: string | null): Promise<string | undefined> => {
  if (!url) return undefined;

  const response = await request<{ url: string }>('GET', {
    path: url,
  });

  return response.url;
};

export const SignedImage: React.FC<
  React.ImgHTMLAttributes<HTMLImageElement> & { originalSize?: boolean }
> = ({ src, style, originalSize, ...rest }) => {
  const [imageSrc, setImageSrc] = React.useState<string | undefined>();
  const isLoadingRef = React.useRef(false);

  React.useEffect(() => {
    if (!src || isLoadingRef.current) return;

    const getImageSrc = async () => {
      try {
        isLoadingRef.current = true;

        const signedUrl = await getSignedUrl(src);

        setImageSrc(signedUrl);
        isLoadingRef.current = false;
      } catch (err) {
        isLoadingRef.current = false;
      }
    };

    getImageSrc();
  }, [isLoadingRef, src]);

  return imageSrc ? (
    <img
      alt=""
      height={originalSize ? '' : '100%'}
      width={originalSize ? '' : '100%'}
      {...rest}
      src={imageSrc}
      style={{
        display: 'block',
        objectFit: 'contain',
        ...style,
      }}
    />
  ) : (
    <Box
      alignItems="center"
      component="div"
      display="flex"
      height={rest.height ?? '100%'}
      justifyContent="center"
      sx={style}
      width={rest.width ?? '100%'}
    >
      <CircularProgress />
    </Box>
  );
};
