import { CircularProgress } from '@mui/material';
import React from 'react';

import { CenteredComponent } from './CenteredComponent';

export const CenteredLoadingSpinner = () => (
  <CenteredComponent>
    <CircularProgress />
  </CenteredComponent>
);
