import { useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { downloadBlob, request } from '@api';
import { useHeaderState } from '@hooks/useHeaderState';
import { useProjectMetadata } from '@hooks/useProjectsMetadata';
import type { Photo } from '@types';
import {
  trackDownloadPhotos,
  trackDownloadSuccess,
  trackDownloadTimePhotos,
} from '../../analytics';
import { DesktopPhotosView } from './DesktopPhotosView';
import { MobilePhotosView } from './MobilePhotosView';

export const downloadZip = async (
  filteredPhotos: Photo[],
  setIsDownloading: React.Dispatch<React.SetStateAction<boolean>>,
  selectedProject: {
    readonly id: string;
    readonly name: string;
  } | null,
) => {
  if (filteredPhotos.length === 0) return;
  trackDownloadPhotos(filteredPhotos.length);
  const downloadStartTime = Date.now();

  setIsDownloading(true);
  const projectId = filteredPhotos[0].projectId;

  const body = { ids: filteredPhotos.map((photo) => photo.id) };

  try {
    const zip = await request('POST', {
      path: `/projects/${projectId}/photos/zip`,
      body,
      responseType: 'blob',
    });
    const date = new Date().toISOString().slice(0, 16).replace('T', '_');
    const fileName = `photos_${selectedProject?.name.replace(/\s/g, '_')}_${date}.zip`;

    downloadBlob(zip as Blob, fileName);
    trackDownloadSuccess(true);
  } catch (e) {
    trackDownloadSuccess(false);
  } finally {
    setIsDownloading(false);
    trackDownloadTimePhotos(Date.now() - downloadStartTime);
  }
};

export const PhotosView: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { selectedProject } = useProjectMetadata();
  const { setProjectTitle } = useHeaderState();

  useEffect(() => {
    if (selectedProject) setProjectTitle(selectedProject.name);
  }, [selectedProject, setProjectTitle]);

  return <>{isMobile ? <MobilePhotosView /> : <DesktopPhotosView />}</>;
};
