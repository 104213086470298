import { DateTime } from 'luxon';

import type { TrenchElementGeoJson } from '@types';

const millisToDays = (ms: number): number => {
  return ms / (1000 * 60 * 60 * 24);
};

export const calcDayRange = (start: DateTime, end: DateTime): number =>
  millisToDays(start.startOf('day').toMillis() - end.startOf('day').toMillis());

export const getMinMaxTimestamps = (
  geojson: TrenchElementGeoJson,
): { min: number; max: number } => {
  let min = Number.MAX_SAFE_INTEGER;
  let max = Number.MIN_SAFE_INTEGER;

  geojson.features.forEach((feature) => {
    const { timestamp } = feature.properties;

    if (timestamp < min) min = timestamp;
    if (timestamp > max) max = timestamp;
  });

  return { min, max };
};

export const formatDate = (date: string) =>
  DateTime.fromISO(date).setLocale('en-gb').toLocaleString({
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
