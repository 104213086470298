import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';
import FilterAltOutlined from '@mui/icons-material/FilterAltOutlined';
import { Box, Button, Divider, IconButton, Input, Paper, Typography } from '@mui/material';
import type { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useSnapshot } from 'valtio';
import { CategorySelect } from '@components/CategorySelect';
import { CenteredLoadingSpinner } from '@components/Common/CenteredLoadingSpinner';
import { SignedImage } from '@components/Common/SignedImage';
import { DateRange } from '@components/MapView/DateRange';
import { MobileFullscreenPhotoOverlay } from '@components/MapView/PreviewImageCarousel/PreviewImageCarousel';
import { ScanDeviceSelect } from '@components/MapView/ScanDevicesSelect';
import { SwipeableEdgeDrawer } from '@components/MapView/SwipableDrawer';
import { filterPhotos, getNamesOfSelectedDevices } from '@components/MapView/helper';
import type { PreviewPhoto } from '@hooks/useMapboxInteractions';
import { useProjectMetadata } from '@hooks/useProjectsMetadata';
import { filterState, setFromDate, setToDate } from '@state/Filter';
import { useProjectData } from '@state/ProjectData';
import type { Photo } from '@types';
import { getGeolocationColor } from '@utils';
import { downloadZip } from './PhotosView';

const PhotoListItem: React.FC<{
  photo: Photo;
  setCurPreviewPhoto: React.Dispatch<PreviewPhoto | null>;
}> = ({ photo, setCurPreviewPhoto }) => {
  const formatDate = (date: string) => {
    const dateObj = new Date(date);

    const dateString = dateObj.toLocaleDateString('de-DE', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    });

    return dateString.split('.').join('/');
  };

  const photoToPreviewPhoto = (photo: Photo): PreviewPhoto => ({
    ...photo,
    type: 'PHOTO',
    projectId: photo.projectId ?? '0',
    comment: photo.comment ?? '',
    category: photo.category?.name ?? 'OTHER',
    annotation: photo.annotation ?? '',
    url: photo.url ?? '',
    urlPreview: photo.urlPreview ?? '',
  });

  return (
    <Button
      onClick={() => setCurPreviewPhoto(photoToPreviewPhoto(photo))}
      sx={{ all: 'unset', display: 'flex', gap: '1rem', minHeight: '96px', padding: '1rem' }}
    >
      <Box
        bgcolor="grey.200"
        borderRadius="5px"
        component="div"
        display="flex"
        maxHeight="96px"
        maxWidth="88px"
        minHeight="96px"
        minWidth="88px"
        overflow="hidden"
        position="relative"
      >
        <Box
          bgcolor={getGeolocationColor(photo.geoPositionSolution)}
          border="3px solid white"
          borderRadius="100%"
          component="div"
          height="16px"
          margin="5px"
          position="absolute"
          right={0}
          width="16px"
        />
        <SignedImage
          alt="preview"
          src={photo.urlPreview ?? '/no_image_available.png'}
          style={{
            minHeight: '100%',
            minWidth: '100%',
            flexShrink: 0,
            objectFit: 'cover',
          }}
        />
      </Box>
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        height="100%"
        maxWidth="100%"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        <Typography sx={{ fontWeight: 'bold' }}>{photo.address}</Typography>
        {photo.comment && <Typography>{`"${photo.comment}"`}</Typography>}
        {photo.annotation && <Typography>{`"${photo.annotation}"`}</Typography>}
        <Typography>{formatDate(photo.recordedDate)}</Typography>
        <Typography maxWidth="90%" overflow="ellipsis">
          {photo.scanDeviceName.replace('ScanDevice', 'SD-')}
        </Typography>
      </Box>
    </Button>
  );
};

interface SwipableFiltersProps {
  closeButtonHandler: () => void;
  fromDate: DateTime;
  toDate: DateTime;
  setFromDate: (date: DateTime) => void;
}

const SwipableFilters: React.FC<SwipableFiltersProps> = ({
  closeButtonHandler,
  fromDate,
  setFromDate,
  toDate,
}) => {
  return (
    <SwipeableEdgeDrawer
      closeButtonHandler={closeButtonHandler}
      initialOpen
      labelText="Filters"
      showCloseButton
    >
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        gap="1.5rem"
        justifyContent="space-evenly"
        width="100%"
      >
        <Box component="div" width="100%">
          <DateRange
            flexGap="1rem"
            fromDate={fromDate}
            setFromDate={setFromDate}
            setToDate={setToDate}
            toDate={toDate}
          />
        </Box>
        <Box component="div" display="flex" gap="1rem" width="100%">
          <CategorySelect />
          <ScanDeviceSelect />
        </Box>
      </Box>
    </SwipeableEdgeDrawer>
  );
};

export const MobilePhotosView: React.FC = () => {
  const { fromDate, toDate, visiblePhotoCategory, scanDevices } = useSnapshot(filterState);
  const { selectedProject } = useProjectMetadata();
  const [search, setSearch] = useState('');
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const photos = useProjectData((state) => state.photos);
  const selectedScanDevices = getNamesOfSelectedDevices(scanDevices);

  const [showFilters, setShowFilters] = useState(false);
  const [curPreviewPhoto, setCurPreviewPhoto] = useState<PreviewPhoto | null>(null);

  const filteredPhotos = useMemo(
    () =>
      filterPhotos(
        photos,
        visiblePhotoCategory,
        fromDate,
        toDate,
        selectedScanDevices,
        search,
      ).sort((p1, p2) => (p1.recordedDate < p2.recordedDate ? 1 : -1)),
    [visiblePhotoCategory, search, photos, fromDate, toDate, selectedScanDevices],
  );

  const closeButtonHandler = () => {
    setShowFilters(false);
  };

  return (
    <>
      {curPreviewPhoto && (
        <MobileFullscreenPhotoOverlay
          previewImages={[curPreviewPhoto]}
          resetPreviewImages={() => setCurPreviewPhoto(null)}
        />
      )}
      {showFilters && (
        <SwipableFilters
          closeButtonHandler={closeButtonHandler}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
        />
      )}
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        flexGrow={1}
        gap="1rem"
        justifyContent="center"
        mt={{ xs: 13, sm: 9 }}
        padding="1rem"
      >
        <Box component="div" display="flex" gap="1rem" justifyContent="space-evenly">
          <Box component="div" display="flex" gap="1rem" padding={0} width="100%">
            <Paper
              sx={(theme) => ({
                padding: 0,
                display: 'flex',
                border: `1px solid ${theme.palette.grey[500]}`,
                borderRadius: '5px',
                boxShadow: 'none',
                width: '100%',
              })}
            >
              <IconButton onClick={() => setShowFilters(!showFilters)}>
                <FilterAltOutlined fontSize="large" />
              </IconButton>
              <Divider
                orientation="vertical"
                sx={(theme) => ({
                  bgcolor: `${theme.palette.grey[500]}`,
                })}
              />
              <Input
                disableUnderline
                onInput={(e) => setSearch((e.target as HTMLInputElement).value)}
                placeholder="Search"
                sx={{ flexGrow: 1, paddingX: '1rem' }}
                value={search}
              />
            </Paper>
            <Button
              color="primary"
              data-testid="Download"
              disabled={isDownloading || filteredPhotos.length === 0}
              onClick={() => downloadZip(filteredPhotos, setIsDownloading, selectedProject)}
              size="medium"
              sx={{
                height: '4em',
                textTransform: 'none',
              }}
              variant="contained"
            >
              {!isDownloading ? (
                <>
                  <FileDownloadOutlined fontSize="large" />
                </>
              ) : (
                <CenteredLoadingSpinner />
              )}
            </Button>
          </Box>
        </Box>
        <Paper sx={{ overflowY: 'scroll', flexGrow: 1, height: 'calc(100vh - 210px)' }}>
          <Virtuoso
            data={filteredPhotos}
            itemContent={(_, photo) => (
              <Box component="div" key={photo.id}>
                <PhotoListItem photo={photo} setCurPreviewPhoto={setCurPreviewPhoto} />
                <Divider />
              </Box>
            )}
            style={{ height: 'calc(100vh - 210px)', width: '100%' }}
          />
        </Paper>
      </Box>
    </>
  );
};
