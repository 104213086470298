import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import React, { FC, ReactNode, useState } from 'react';

const Puller = styled(Box)(({ theme }) => ({
  '&:before': {
    content: '""',
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[700],
    borderRadius: theme.shape.borderRadius,
    display: 'inline-block',
  },
  position: 'absolute',
  left: 0,
  right: 0,
  textAlign: 'center',
}));

interface SwipeableEdgeDrawerProps {
  children?: ReactNode | undefined;
  labelText: string;
  hidden?: boolean;
  closeButtonHandler?: () => unknown;
  showCloseButton?: boolean;
  initialOpen?: boolean;
}

export const SwipeableEdgeDrawer: FC<SwipeableEdgeDrawerProps> = ({
  children,
  labelText,
  hidden,
  closeButtonHandler,
  showCloseButton,
  initialOpen,
}) => {
  const [open, setOpen] = useState(initialOpen ?? false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleCloseButton = (newOpen: boolean) => () => {
    if (closeButtonHandler) {
      closeButtonHandler();
    }
    setOpen(newOpen);
  };

  if (hidden) {
    return null;
  }

  const drawerBleeding = 56;

  return (
    <SwipeableDrawer
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        sx: (theme) => ({
          overflow: 'visible',
          background: theme.palette.mode === 'light' ? 'white' : grey[900],
        }),
      }}
      anchor="bottom"
      disableSwipeToOpen={false}
      onClick={!open ? toggleDrawer(true) : undefined}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      open={open}
      swipeAreaWidth={drawerBleeding}
      variant="temporary"
    >
      <Box
        component="div"
        sx={(theme) => ({
          borderTopLeftRadius: theme.shape.borderRadius,
          borderTopRightRadius: theme.shape.borderRadius,
          position: 'absolute',
          top: -drawerBleeding,
          left: 0,
          right: 0,
          visibility: 'visible',
          background: theme.palette.mode === 'light' ? 'white' : grey[900],
        })}
      >
        <Puller onClick={toggleDrawer(false)} />
        <Box
          alignItems="center"
          component="div"
          display="flex"
          justifyContent="space-between"
          margin="auto"
          paddingTop="5px"
          width="90%"
        >
          <Typography sx={{ p: 2, color: 'text.secondary', paddingX: 0 }}>{labelText}</Typography>
          {showCloseButton && open && (
            <Box component="div" display="flex" justifyContent="flex-end">
              <CloseIcon
                cursor="pointer"
                onClick={handleCloseButton(false)}
                sx={{ color: 'text.secondary' }}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box component="div" sx={{ padding: '1rem', overflowY: 'auto' }}>
        {children}
      </Box>
    </SwipeableDrawer>
  );
};
