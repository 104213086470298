import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import {
  Map,
  FullscreenControl,
  Marker,
  NavigationControl,
  Popup,
  ScaleControl,
} from 'react-map-gl';
import type { MapRef } from 'react-map-gl';
import { Carousel } from 'react-responsive-carousel';

import { useEnvironment } from '@hooks/useEnvironment';

import { mapStyles } from '../MapView/constants';

import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './ClarificationCaseView.css';

export const ClarificationCaseView = () => {
  const { mapboxAccessToken } = useEnvironment();
  const mapRef = React.useRef<MapRef>(null);
  const [mapStyle] = useState<string>('dark');
  const [popupInfo, setPopupInfo] = useState<string>('');
  const image = 'https://zamit.eu/wp-content/uploads/2017/04/leiter.jpg';
  const data = [
    { image: image, shortDesc: 'Trasse 1' },
    { image: image, shortDesc: 'Trasse 2' },
    { image: image, shortDesc: 'Trasse 3' },
  ];

  return (
    <Box component="div" position="relative">
      <Map
        initialViewState={{
          latitude: 53.529549712489455,
          longitude: 8.060809099265862,
          zoom: 17,
        }}
        mapStyle={mapStyles[mapStyle as keyof typeof mapStyles]}
        mapboxAccessToken={mapboxAccessToken}
        ref={mapRef}
        style={{ width: window.innerWidth, height: window.innerHeight }}
      >
        <Marker
          anchor="bottom"
          key="marker-test"
          latitude={53.529549712489455}
          longitude={8.060809099265862}
        >
          <svg
            fill="#d00"
            height={40}
            onClick={() => setPopupInfo('test')}
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
            />
            <path
              d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
            />
          </svg>
        </Marker>

        {popupInfo && (
          <Popup
            anchor="top"
            closeOnClick={false}
            latitude={53.529549712489455}
            longitude={8.060809099265862}
            onClose={() => setPopupInfo('')}
          >
            <Box component="div">
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'lightgrey',
                  borderRadius: '5px',
                }}
              >
                <Box
                  alignItems="center"
                  component="div"
                  display="flex"
                  justifyContent="center"
                  textAlign="center"
                >
                  <h3>NVT 9908</h3>
                  <Button size="small">Info</Button>
                </Box>
                <Carousel showArrows={true}>
                  {data.map((item, index) => (
                    <div key={index}>
                      <p>{item.shortDesc}</p>
                      <img alt={item.shortDesc} src={image} />
                    </div>
                  ))}
                </Carousel>
              </div>
            </Box>
          </Popup>
        )}

        <FullscreenControl position="bottom-left" />
        <NavigationControl position="bottom-left" />
        <ScaleControl />
      </Map>
    </Box>
  );
};
