import type { LngLatBoundsLike } from 'mapbox-gl';
import { create } from 'zustand';
import { request } from '@api';
import type { Photo, Scan, TrenchElementGeoJson } from '@types';
import { germanyBounds } from '@utils';

export const initialGeoJson: TrenchElementGeoJson = {
  type: 'FeatureCollection',
  features: [],
};

export interface ProjectData {
  trenchElementGeoJson: TrenchElementGeoJson;
  setTrenchElementGeoJson: (json: TrenchElementGeoJson) => void;
  bounds: LngLatBoundsLike;
  setBounds: (bounds: LngLatBoundsLike) => void;
  photos: Photo[];
  setPhotos: (photos: Photo[]) => void;
  patchPhoto: (projectId: string, photoId: string) => (newAnnotation: string) => void;
  scans: Scan[];
  setScans: (scans: Scan[]) => void;
  resetProjectData: () => void;
}

const initialState = {
  photos: [],
  trenchElementGeoJson: initialGeoJson,
  bounds: germanyBounds,
  scans: [],
};

export const useProjectData = create<ProjectData>((set) => ({
  ...initialState,
  setPhotos: (photos) => set({ photos }),
  patchPhoto: (projectId, photoId) => (newAnnotation) => {
    request<Photo, { annotation: string }>('PATCH', {
      path: `/projects/${projectId}/photo/${photoId}`,
      body: { annotation: newAnnotation },
    }).then((patchedPhoto) =>
      set((state) => ({
        photos: state.photos.map((p) => (p.id === patchedPhoto.id ? patchedPhoto : p)),
      })),
    );
  },
  setBounds: (bounds: LngLatBoundsLike) => set({ bounds }),
  setTrenchElementGeoJson: (trenchElementGeoJson: TrenchElementGeoJson) =>
    set({ trenchElementGeoJson }),
  setScans: (scans: Scan[]) => set({ scans }),
  resetProjectData: () => set(initialState),
}));
