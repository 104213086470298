import { Typography, Box } from '@mui/material';
import * as React from 'react';
import { getSignedUrl, SignedImage } from '@components/Common/SignedImage';
import type { PreviewPhoto, PreviewTopdownView } from '@hooks/useMapboxInteractions';
import { PreviewPhotoDetails } from './PreviewPhotoDetails';
import { PreviewTopdownViewDetails } from './PreviewTopdownViewDetails';
import classes from './style.module.css';

const onImageButtonClick = async (url?: string | null) => {
  const imageSrc = await getSignedUrl(url);

  window.open(imageSrc, '_blank');
};

const renderDetails = (previewPhoto: PreviewPhoto | PreviewTopdownView) => {
  if (previewPhoto?.type === 'PHOTO') {
    return <PreviewPhotoDetails previewPhoto={previewPhoto} />;
  }

  if (previewPhoto?.type === 'SCAN_TOPDOWN_VIEW') {
    return <PreviewTopdownViewDetails preview={previewPhoto} />;
  }

  return null;
};

export const PreviewImageContent: React.FC<{
  previewPhoto?: PreviewPhoto | PreviewTopdownView;
}> = ({ previewPhoto }) => {
  if (!previewPhoto) return null;

  const { id, urlPreview, url } = previewPhoto;
  const details = renderDetails(previewPhoto);

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        height: '100%',
        marginTop: '10px',
        marginBottom: '10px',
        justifyContent: 'space-between',
      }}
    >
      {url ? (
        <button className={classes.imgBtn} onClick={() => onImageButtonClick(url)}>
          <SignedImage alt="photo of a trench" key={id} src={urlPreview || url} />
        </button>
      ) : (
        <Typography>
          Top Down View is still being processed. Please come back in a few minutes.
        </Typography>
      )}
      {details}
    </Box>
  );
};
