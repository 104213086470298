import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useSnapshot } from 'valtio';

import { filterState, setVisiblePhotoCategory } from '@state/Filter';

export const CategorySelect = () => {
  const { visiblePhotoCategory, photoCategories } = useSnapshot(filterState);

  return (
    <FormControl sx={{ flex: 1, minWidth: '10px', width: '100%' }}>
      <InputLabel id="cat-select">Category</InputLabel>
      <Select
        label="Category"
        labelId="cat-select"
        onChange={(e) => setVisiblePhotoCategory(e.target.value)}
        value={visiblePhotoCategory}
      >
        {photoCategories.map((cat, i) => (
          <MenuItem defaultChecked={i === 0} key={cat} value={cat}>
            {cat}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
