import axios from 'axios';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { request } from '@api';
import { alertBox } from '@components/MessageBox';
import {
  appendAvailableScanDevices,
  setAvailableSurfaceTypes,
  setFromDate,
  setToDate,
} from '@state/Filter';
import { initialGeoJson, useProjectData } from '@state/ProjectData';
import type { TrenchElementGeoJson } from '@types';
import { getBounds, getMinMaxTimestamps } from '@utils';
import { addTimestampAttributeToScanDevices } from './helper';

const defaultToDate = DateTime.now();

export const useGeoJson = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { setTrenchElementGeoJson, setBounds, trenchElementGeoJson, bounds } = useProjectData(
    ({ setTrenchElementGeoJson, setBounds, trenchElementGeoJson, bounds }) => ({
      setTrenchElementGeoJson,
      setBounds,
      trenchElementGeoJson,
      bounds,
    }),
  );

  useEffect(() => {
    request<{ geojsonStorageUrl: string }>('GET', {
      path: `/projects/${projectId}/geojson`,
    })
      .then(({ geojsonStorageUrl }) => axios.get<TrenchElementGeoJson>(geojsonStorageUrl))
      .then(({ data: geojson }) => {
        const bounds = getBounds(geojson);

        if (!geojson?.features?.length) {
          alertBox('GeoJson loaded but there are no trenches/elements in it!');
          setTrenchElementGeoJson(initialGeoJson);
          setBounds(bounds);

          return;
        }
        const { min } = getMinMaxTimestamps(geojson);

        setFromDate(DateTime.fromMillis(min));
        setToDate(defaultToDate);

        const surfaceTypes = [
          ...new Set(
            geojson.features.map((feature) => feature.properties?.surfaceType).filter(Boolean),
          ),
        ];

        const scanEvents = geojson.features
          .filter((f) => !!f.properties?.scanDeviceName)
          .map((f) => ({
            scanDeviceName: f.properties.scanDeviceName,
            timestamp: f.properties.timestamp,
          }));

        const allScanDevices = addTimestampAttributeToScanDevices(scanEvents);

        setAvailableSurfaceTypes(surfaceTypes);
        setTrenchElementGeoJson(geojson);
        appendAvailableScanDevices(allScanDevices);
        setBounds(bounds);
      });
  }, [projectId, setBounds, setTrenchElementGeoJson]);

  return {
    trenchElementGeoJson,
    bounds,
  };
};
