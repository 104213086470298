import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import { Box, IconButton, TextField } from '@mui/material';
import React, { FC, useState } from 'react';
import { successBox } from '@components/MessageBox';

export const PhotoAnnotation: FC<{
  photoAnnotation?: string;
  patchPhoto: (newAnnotation: string) => void;
}> = ({ photoAnnotation = '', patchPhoto }) => {
  const [text, setText] = useState(photoAnnotation);

  return (
    <Box
      component="div"
      data-testid="annotation"
      display="flex"
      gap="0.3rem"
      style={{ width: '100%' }}
    >
      <TextField
        fullWidth
        onChange={(e) => setText(e.target.value)}
        placeholder="Write annotation"
        style={{ opacity: photoAnnotation !== text ? 1 : 0.4 }}
        value={text}
      />
      <IconButton
        data-testid="undoAnnotation"
        disabled={photoAnnotation === text}
        onClick={() => setText(photoAnnotation)}
        style={{ border: '1px solid #bdbdbd', borderRadius: '5px' }}
      >
        <ClearIcon />
      </IconButton>
      <IconButton
        data-testid="patchAnnotation"
        disabled={photoAnnotation === text}
        onClick={() => {
          patchPhoto(text);
          successBox('Annotation successfully changed', 6000);
        }}
        style={{ border: '1px solid #bdbdbd', borderRadius: '5px' }}
      >
        <DoneIcon />
      </IconButton>
    </Box>
  );
};
