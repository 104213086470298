import { GlobalHeader } from '@deepup/global-header';
import { Stack } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useHeaderState } from '@hooks/useHeaderState';

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { title, showBackButton } = useHeaderState();
  const navigate = useNavigate();

  const handleBack = () => navigate('/');

  return (
    <>
      <GlobalHeader
        language="en"
        onClick={handleBack}
        showAppMenu
        showBackButton={showBackButton}
        showLanguageToggle={false}
        showThemeToggle
        title={title}
      />
      <Stack mt={{ xs: 7, sm: 10 }}>{children}</Stack>
    </>
  );
};
