import { Grid } from '@mui/material';
import React, { type FC, ReactElement } from 'react';

export const CenteredComponent: FC<{ children: ReactElement }> = ({ children }) => (
  <Grid
    alignItems="center"
    container
    direction="column"
    spacing={0}
    style={{ position: 'relative', height: '100%', width: '100%', justifyContent: 'center' }}
  >
    <Grid item>{children}</Grid>
  </Grid>
);
