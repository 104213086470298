import { ArrowBoxOut } from '@deepup/icons';
import { useTheme, Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { type FC } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import './mapbox-popup-overrides.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './carousel-overrides.css';

export const MapView: FC = () => {
  const theme = useTheme();

  return (
    <Box
      alignItems="center"
      component="div"
      display="flex"
      flexDirection="column"
      position="relative"
      sx={{
        top: 49,
        height: 'calc(100vh - 105px)',

        [theme.breakpoints.up('md')]: {
          top: 73,
          height: 'calc(100vh - 160px)',
        },
      }}
    >
      <Box component="div" display="flex" flexDirection="column" gap={1} py={8}>
        <img alt="Page was moved" src="/page_removed.png" />
        <Typography variant="h6">This page doesn’t exist anymore.</Typography>
        <Typography variant="body1">The map is removed from the Cockpit.</Typography>
        <Typography variant="body1">You can find the map in the ConMon App.</Typography>
        <Box component="div" display="flex" justifyContent="center" py={1}>
          <Button
            color="primary"
            component="a"
            href="https://conmon.deepup.io/"
            size="medium"
            startIcon={<ArrowBoxOut fill="currentColor" height="24px" width="24px" />}
            target="_blank"
            variant="contained"
          >
            Go To Conmon
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
