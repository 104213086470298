import { DateTime } from 'luxon';
import type { ScanDeviceAttributes } from '@state/Filter';
import type { LayingType, Photo, PhotoGeoJson, SurfaceType, Scan, PreviewPhotoType } from '@types';

import type { RawSegmentData, SegmentData } from './types';

export const mapPhotosToGeoJson = (photos: Photo[]) => {
  const features = photos.map((photo, index) => ({
    id: index,
    type: 'Feature',
    properties: { ...photo, type: 'PHOTO' as const satisfies PreviewPhotoType },
    geometry: {
      type: 'Point',
      coordinates: [photo.long, photo.lat],
    },
  }));

  return {
    id: 'f3fde267-1337-4546-bcf4-805bd4d0ac1e',
    type: 'FeatureCollection',
    features,
  } as PhotoGeoJson;
};

export const mapScanTopdownViewsToGeoJson = (scans: Scan[]) => {
  // show only scans with meshes
  // scans = scans.filter((scan) => scan.hdMeshView !== null);

  const features = scans.map((data, index) => ({
    id: index + 1,
    type: 'Feature' as const,
    geometry: {
      type: 'Point' as const,
      coordinates: [data.lon, data.lat],
    },
    properties: {
      ...data,
      id: data.scanId,
      type: 'SCAN_TOPDOWN_VIEW' as const satisfies PreviewPhotoType,
    },
  }));

  return {
    id: 'eb05b769-4c34-472d-a20c-d4ad3956a0a4',
    type: 'FeatureCollection' as const,
    features,
  };
};

export const getNamesOfSelectedDevices = (
  scanDeviceVisibilities: Record<string, ScanDeviceAttributes>,
) => Object.keys(scanDeviceVisibilities).filter((key) => scanDeviceVisibilities[key].isVisible);

const toEmptyString = (value?: string | null): string => value || '';
const isDateInRange = (date: string, fromDate: DateTime, toDate: DateTime) => {
  const dateAsDateTime = DateTime.fromISO(date).startOf('day');

  return dateAsDateTime <= toDate.startOf('day') && dateAsDateTime >= fromDate.startOf('day');
};

export const filterPhotos = (
  photos: Photo[],
  category: string,
  fromDate: DateTime,
  toDate: DateTime,
  selectedScanDevices: string[],
  searchText = '',
): Photo[] =>
  photos.filter(
    (photo) =>
      // it is necessary to use boolean OR here instead of ?? because comment or address can be null
      (
        toEmptyString(photo.address) +
        toEmptyString(photo.comment) +
        toEmptyString(photo.annotation) +
        toEmptyString(photo.category?.name) +
        toEmptyString(photo.recordedDate.toString()) +
        toEmptyString(photo.scanDeviceName)
      )
        .toLowerCase()
        .includes(searchText.toLowerCase()) &&
      isDateInRange(photo.recordedDate, fromDate, toDate) &&
      selectedScanDevices.includes(photo.scanDeviceName) &&
      (category === 'ALL' || category === photo.category?.name),
  );

export const filterScans = (
  scans: Scan[],
  fromDate: DateTime,
  toDate: DateTime,
  selectedScanDevices: string[],
): Scan[] =>
  scans.filter(
    (scan) =>
      scan.endDate &&
      isDateInRange(scan.endDate, fromDate, toDate) &&
      selectedScanDevices.includes(toEmptyString(scan.device?.name)),
  );

export function parseSegmentData(rawSegmentData: RawSegmentData): SegmentData {
  const usageTypes = JSON.parse(rawSegmentData['usageTypes']);
  const elementIds = JSON.parse(rawSegmentData['elementIds']);

  const usageType = usageTypes.includes('TRASS') ? 'TRASS' : 'HOUSE_LEAD';
  const elements = elementIds.map((elementId: string) => JSON.parse(rawSegmentData[elementId]));
  const date = DateTime.fromMillis(rawSegmentData.timestamp).toFormat('dd/MM/yyyy');
  const surfaceType = rawSegmentData['surfaceType'] as SurfaceType;
  const layingType = rawSegmentData['layingType']
    ? (rawSegmentData['layingType'] as LayingType)
    : 'OPEN_CONSTRUCTION';

  return {
    usageType,
    elements,
    date,
    surfaceType,
    layingType,
    length: rawSegmentData.length,
    scanDeviceName: rawSegmentData.scanDeviceName,
  };
}

export const mergeFeatureCollections = (...featureCollections: Array<PhotoGeoJson | undefined>) => {
  const featureCollection: PhotoGeoJson = {
    type: 'FeatureCollection',
    features: [],
  };

  for (const fc of featureCollections) {
    if (!fc) continue;

    featureCollection.features.push(...fc.features);
  }

  return featureCollection;
};
