import type { FeatureCollection, LineString, Point, Feature, GeoJsonProperties } from 'geojson';
import type { LngLatLike } from 'mapbox-gl';

export type Segment = Feature<LineString & { coordinates: number[][] }, TrenchSegmentProperties>;

export type TrenchSegmentProperties = GeoJsonProperties & {
  timestamp: number;
  usageTypes: ElementTypeUsage[];
  surfaceType: SurfaceType;
  layingType: LayingType;
  scanDeviceName: string;
};
export type TrenchElementGeoJson = FeatureCollection<LineString, TrenchSegmentProperties>;

export type PhotoGeoJson = FeatureCollection<Point>;

export const elementTypeUsages = ['HOUSE_LEAD', 'TRASS'] as const;

export type ElementTypeUsage = (typeof elementTypeUsages)[number];

export const layingTypes = [
  'SOIL_DISPLACEMENT',
  'PULL',
  'OPEN_CONSTRUCTION',
  'HORIZONTAL_DIRECTIONAL_DRILLING',
  'PLOUGH',
  'MICRO_TRENCHING',
  'LAYJET',
] as const;
export type LayingType = (typeof layingTypes)[number];

export const surfaceTypes = [
  'ASPHALT',
  'CONCRETE',
  'UNPAVED_GRASS',
  'SIMPLE_BRICK',
  'HIGH_QUALITY_BRICK',
  'MISSING',
] as const;

export type SurfaceType = (typeof surfaceTypes)[number];

export const usageTypeColors: Record<ElementTypeUsage, string> = {
  HOUSE_LEAD: '#0000ff',
  TRASS: '#990033',
};

// TODO: currently support only light mode colors
export const surfaceTypeColors: Record<SurfaceType, string> = {
  UNPAVED_GRASS: '#7CFC00',
  SIMPLE_BRICK: '#BF40BF',
  HIGH_QUALITY_BRICK: '#570861',
  ASPHALT: '#006ee6',
  MISSING: '#f28c8a',
  CONCRETE: '#FFA500',
};

// TODO: currently support only  light mode colors
export const layingTypeColors: Record<LayingType, string> = {
  SOIL_DISPLACEMENT: '#d5b60a',
  PULL: '#FF50F5',
  OPEN_CONSTRUCTION: '#990033',
  HORIZONTAL_DIRECTIONAL_DRILLING: '#7CFC00',
  PLOUGH: '#4597C9',
  LAYJET: '#FEE9FE',
  MICRO_TRENCHING: '#DC2629',
};

// https://github.com/microsoft/TypeScript/issues/1897#issuecomment-919452804
export type Json = string | number | boolean | null | Json[] | { [key: string]: Json };

export const ascQualitySortedGeoPositionSolutions = ['CORELOCATION', 'FLOAT', 'FIXED'] as const;

export type GeoPositionSolution = (typeof ascQualitySortedGeoPositionSolutions)[number];

export interface Photo {
  id: string;
  address: string;
  comment: string | null;
  scanDeviceName: string;
  deviceId: string;
  geoPositionSolution: GeoPositionSolution;
  lat: number;
  long: number;
  projectId: string | null;
  recordedDate: string;
  category: { name: string } | null;
  url: string | null;
  urlPreview: string | null;
  urlThumbnail: string | null;
  annotation: string | null;
}

export interface Scan {
  scanId: string;
  beginDate: string | null;
  endDate: string | null;
  device: {
    id: string;
    name: string;
  } | null;
  lat: number;
  lon: number;
  topDownView: {
    details: string;
    id: string;
    url: string | null;
    lat: number;
    lon: number;
  };
  track: {
    id: string;
  };
  length: number | null;
  hdMeshView: {
    id: string;
    url: string;
  } | null;
}

export const previewPhotoTypes = ['PHOTO', 'SCAN_TOPDOWN_VIEW'] as const;

export type PreviewPhotoType = (typeof previewPhotoTypes)[number];

export type LatLngNarrowType = Extract<LngLatLike, { lng: number; lat: number }>;

export type LatLngBoundsNarrowType = [LatLngNarrowType, LatLngNarrowType];
