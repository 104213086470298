export const mapStyles = {
  dark: 'mapbox://styles/mapbox/dark-v10',
  light: 'mapbox://styles/mapbox/streets-v11',
  satellite: 'mapbox://styles/mapbox/satellite-v9',
};

export const clusterSourceConfig = {
  maxzoom: 20,
  clusterMaxZoom: 16,
  clusterRadius: 25,
};

export enum PhotoColor {
  CoreLocation = '#d04505',
  Default = '#009982',
  Cluster = 'lightgrey',
}

export enum TopdownViewColor {
  Default = 'darkblue',
}
