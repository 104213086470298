import { useAuth0 } from '@auth0/auth0-react';
import { LoadingScreen } from '@deepup/loading-screen';
import { Container } from '@mui/material';
import Alert from '@mui/material/Alert';
import React from 'react';
import ReactGA from 'react-ga4';

import { setup } from '@api';
import { useEnvironment } from '@hooks/useEnvironment';
import { setLoggedInUser } from '@state/LoggedInUser';

export const Auth = ({ children }: { children: React.ReactElement }): React.ReactElement => {
  const { apiUrl, analyticsMessId, isDev } = useEnvironment();
  const { isAuthenticated, isLoading, error, loginWithRedirect, getAccessTokenSilently, user } =
    useAuth0();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated && error) {
    return (
      <Container>
        <Alert severity="error">{error.message}</Alert>
        <div style={{ margin: 8, color: 'white' }}>
          <span>Please </span>
          logout
          <span>, make sure to have the correct permission set and try again</span>
        </div>
      </Container>
    );
  }

  if (!isAuthenticated) {
    loginWithRedirect({ appState: { returnTo: window.location.href } }).catch(
      (e) => isDev && console.error(e),
    );

    return <LoadingScreen />;
  }

  if (user?.email) setLoggedInUser(user.email.split('@')[1]);

  setup({
    baseUrl: apiUrl,
    getAccessToken: () => getAccessTokenSilently(),
  });

  ReactGA.initialize(analyticsMessId);

  return children;
};
