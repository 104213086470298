import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { request } from '@api';
import { appendAvailableScanDevices } from '@state/Filter';
import { useProjectData } from '@state/ProjectData';
import type { Scan } from '@types';
import { addTimestampAttributeToScanDevices, type ScanEvent } from './helper';

export const useScans = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { setScans } = useProjectData(({ setScans }) => ({
    setScans,
  }));

  useEffect(() => {
    const fetchScans = async () => {
      try {
        const loadedScans = await request<Scan[]>('GET', { path: `/projects/${projectId}/scans` });

        setScans(loadedScans);

        const scanEvents = loadedScans
          // Filter out scans without device name and scan date
          .reduce<ScanEvent[]>((results, scan) => {
            if (scan.device && scan.endDate) {
              results.push({
                scanDeviceName: scan.device.name,
                timestamp: DateTime.fromISO(scan.endDate).toMillis(),
              });
            }

            return results;
          }, []);
        const allScanDevices = addTimestampAttributeToScanDevices(scanEvents);

        appendAvailableScanDevices(allScanDevices);
      } catch (_) {
        // This catches the error and does nothing with it.
        // Error might be that the scans are not available.
      }
    };

    fetchScans();
  }, [projectId, setScans]);
};
