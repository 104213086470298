import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { request } from '@api';

interface UserPermissions {
  documentation: boolean | null;
}

interface UserPermissionsResponse {
  documentation: boolean;
}

export const useProjectUserPermissions = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [userPermissions, setUserPermissions] = useState<UserPermissions>({ documentation: null });

  useEffect(() => {
    if (projectId) {
      request<UserPermissionsResponse>('GET', {
        path: `/projects/${projectId}/user-permissions`,
      }).then((response) => {
        setUserPermissions(response);
      });
    }
  }, [projectId]);

  return userPermissions;
};
