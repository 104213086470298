import type { GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { PhotoAnnotation } from '@components/Common/PhotoAnnotation';
import type { ProjectData } from '@state/ProjectData';
import type { Photo } from '@types';
import {
  renderAGgeoPositionSolution,
  renderAsDate,
  renderAsPhoto,
  renderMultilineText,
} from './tableCellRenderers';

export const getColumnDefinitions = (
  patchPhoto: ProjectData['patchPhoto'],
): GridColDef<Photo>[] => [
  {
    disableColumnMenu: true,
    field: 'id',
    filterable: false,
    headerName: 'Photo',
    hideable: true,
    width: 100,
    sortable: true,
    type: 'string',
    renderCell: renderAsPhoto,
  },
  {
    disableColumnMenu: true,
    field: 'address',
    filterable: false,
    headerName: 'Address',
    hideable: true,
    flex: 1,
    sortable: true,
    type: 'string',
    renderCell: (params) => renderMultilineText(params.row.address),
  },
  {
    disableColumnMenu: true,
    field: 'comment',
    filterable: false,
    headerName: 'Comment',
    hideable: false,
    flex: 1,
    sortable: false,
    type: 'string',
    renderCell: (params) => renderMultilineText(params.row.comment),
  },
  {
    disableColumnMenu: true,
    field: 'annotation',
    filterable: false,
    headerName: 'Annotation',
    hideable: false,
    flex: 1.75,
    sortable: true,
    type: 'string',
    renderCell: (params) => (
      <PhotoAnnotation
        patchPhoto={patchPhoto(params.row.projectId ?? '', params.row.id)}
        photoAnnotation={params.row.annotation ?? ''}
      />
    ),
  },
  {
    disableColumnMenu: true,
    field: 'scanDeviceName',
    filterable: false,
    headerName: 'Device',
    hideable: false,
    flex: 1,
    sortable: true,
    type: 'string',
    renderCell: (params) => renderMultilineText(params.row.scanDeviceName),
  },
  {
    disableColumnMenu: true,
    field: 'category',
    filterable: false,
    headerName: 'Category',
    hideable: false,
    sortable: true,
    flex: 1,
    maxWidth: 160,
    type: 'string',
    renderCell: (params) => params.row.category?.name,
  },
  {
    disableColumnMenu: true,
    field: 'geoPositionSolution',
    filterable: false,
    headerName: 'Precision',
    hideable: false,
    sortable: false,
    flex: 1,
    maxWidth: 80,
    type: 'string',
    renderCell: renderAGgeoPositionSolution,
  },
  {
    disableColumnMenu: true,
    field: 'recordedDate',
    filterable: false,
    headerName: 'Date',
    hideable: false,
    sortable: true,
    flex: 1,
    maxWidth: 100,
    type: 'string',
    renderCell: (params) => renderAsDate(params.row.recordedDate),
  },
];
