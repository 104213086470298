import { useMediaQuery, useTheme } from '@mui/material';
import React, { type FC, memo } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { FullscreenMobileOverlay } from '@components/Common/FullscreenMobileOverlay';
import { RightDrawer } from '@components/Common/RightDrawer';
import type { PreviewPhoto } from '@hooks/useMapboxInteractions';
import { PreviewImageContent } from '.';

export const PreviewImageCarousel: FC<{
  previewImages?: PreviewPhoto[];
  resetPreviewImages: () => void;
}> = memo(function PreviewImageCarousel({ previewImages, resetPreviewImages }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      {!isMobile ? (
        <DesktopPhotoDrawer previewImages={previewImages} resetPreviewImages={resetPreviewImages} />
      ) : (
        <MobileFullscreenPhotoOverlay
          previewImages={previewImages}
          resetPreviewImages={resetPreviewImages}
        />
      )}
    </>
  );
});

const DesktopPhotoDrawer: FC<{
  previewImages?: PreviewPhoto[];
  resetPreviewImages: () => void;
}> = ({ previewImages, resetPreviewImages }) => {
  const open = !!previewImages?.length;

  return (
    <RightDrawer onClose={resetPreviewImages} open={open}>
      <ImagePreviewWithData previewImages={previewImages} />
    </RightDrawer>
  );
};

export const MobileFullscreenPhotoOverlay: FC<{
  previewImages?: PreviewPhoto[];
  resetPreviewImages: () => void;
}> = ({ previewImages, resetPreviewImages }) => {
  const open = !!previewImages?.length;

  return (
    <FullscreenMobileOverlay onClose={resetPreviewImages} open={open}>
      <ImagePreviewWithData previewImages={previewImages} />
    </FullscreenMobileOverlay>
  );
};

const ImagePreviewWithData: FC<{
  previewImages?: PreviewPhoto[];
}> = ({ previewImages }) => {
  const onlyOnePhoto = previewImages?.length === 1;

  return (
    <>
      {onlyOnePhoto ? (
        <PreviewImageContent previewPhoto={previewImages[0]} />
      ) : (
        <Carousel
          infiniteLoop={true}
          key={previewImages?.[0]?.id}
          preventMovementUntilSwipeScrollTolerance={true}
          showArrows
          showIndicators={false}
          showStatus={true}
          showThumbs={false}
        >
          {previewImages?.map((item, index) => (
            <PreviewImageContent key={index} previewPhoto={item} />
          ))}
        </Carousel>
      )}
    </>
  );
};
