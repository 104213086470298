import { LoadingScreen } from '@deepup/loading-screen';
import React, { useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { MapView } from '@components/MapView';
import { PhotosView } from '@components/PhotosView';
import { useGeoJson } from '@hooks/useGeoJson';
import { usePhotos } from '@hooks/usePhotos';
import { useScans } from '@hooks/useScans';
import { resetAvailableScanDevices } from '@state/Filter';
import { useProjectData } from '@state/ProjectData';

import { NavigationBar } from './NavigationBar';

export const ProjectView = () => {
  usePhotos(); // fetch photos in the beginning for all pages
  useScans(); // fetch scans in the beginning for all pages and refetch every 3 minutes
  const { resetProjectData } = useProjectData();

  const { trenchElementGeoJson, bounds } = useGeoJson();
  const { projectId } = useParams<{ projectId: string }>();

  useEffect(() => {
    resetAvailableScanDevices();
    resetProjectData();
  }, [projectId, resetProjectData]);

  return (
    <>
      <NavigationBar />
      <Routes>
        <Route
          element={trenchElementGeoJson && bounds ? <MapView /> : <LoadingScreen />}
          path="/map"
        />
        <Route element={<PhotosView />} path="/photos" />
      </Routes>
    </>
  );
};
