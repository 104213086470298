import { Stack, TextField, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHeaderState } from '@hooks/useHeaderState';
import { useAllProjectsMetadata, useProjectMetadata } from '@hooks/useProjectsMetadata';

export function ProjectsView() {
  const { setSelectedProject } = useProjectMetadata();
  const [searchQuery, setSearchQuery] = useState('');
  const { allProjects } = useAllProjectsMetadata();
  const navigate = useNavigate();

  const { clearProjectTitle } = useHeaderState();

  useEffect(() => {
    setSelectedProject(null);
    clearProjectTitle();
  }, [setSelectedProject, clearProjectTitle]);

  const handleOnClick = (projectId: string) => {
    navigate(`/${projectId}/photos`);
  };

  if (!allProjects.length) {
    return null;
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Project name',
      width: 150,
      flex: 1,
      filterable: false,
    },
  ];

  const filteredProjects = allProjects.filter((project) => {
    const searchQueryLower = searchQuery.toLowerCase();
    const projectNameLower = project.name.toLowerCase();

    return projectNameLower.includes(searchQueryLower);
  });

  type ProjectRow = GridRowParams<(typeof filteredProjects)[0]>;

  return (
    <Stack
      direction="column"
      sx={(theme) => ({
        margin: '2rem auto',
        width: '90%',
        [theme.breakpoints.up('md')]: {
          maxWidth: '50%',
        },
      })}
    >
      <Stack alignItems="center" direction="row" justifyContent="space-between" mb={2}>
        <Typography fontWeight="bold" gutterBottom variant="h6">
          Projektübersicht
        </Typography>
        <TextField
          label="Search project"
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={(theme) => ({
            width: theme.spacing(32),
          })}
          value={searchQuery}
        />
      </Stack>

      <DataGrid
        columns={columns}
        disableColumnMenu
        disableRowSelectionOnClick
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
        }}
        onRowClick={({ row }: ProjectRow) => handleOnClick(row.id)}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        rows={filteredProjects}
      />
    </Stack>
  );
}
