import { useMemo } from 'react';
import { useSnapshot } from 'valtio';
import {
  filterScans,
  getNamesOfSelectedDevices,
  mapScanTopdownViewsToGeoJson,
} from '@components/MapView/helper';
import { filterState } from '@state/Filter';
import { useProjectData } from '@state/ProjectData';
import type { PhotoGeoJson } from '@types';

export const useTopDownViewsGeoJson = () => {
  const { fromDate, showTopdownViews, toDate, scanDevices } = useSnapshot(filterState);
  const { scans } = useProjectData(({ scans }) => ({
    scans,
  }));

  const topdownViewsGeojson: PhotoGeoJson | undefined = useMemo(() => {
    if (!showTopdownViews) return undefined;

    const selectedScanDevices = getNamesOfSelectedDevices(scanDevices);
    const filteredScans = filterScans(scans, fromDate, toDate, selectedScanDevices);

    return mapScanTopdownViewsToGeoJson(filteredScans);
  }, [fromDate, scans, showTopdownViews, toDate, scanDevices]);

  return topdownViewsGeojson;
};
