import Clear from '@mui/icons-material/Clear';
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';
import { Box, Button, IconButton, Paper, TextField, Typography } from '@mui/material';
import { DataGrid, type GridEventListener } from '@mui/x-data-grid';
import React, { useMemo, useState } from 'react';
import { useSnapshot } from 'valtio';
import { CategorySelect } from '@components/CategorySelect';
import { CenteredLoadingSpinner } from '@components/Common/CenteredLoadingSpinner';
import { DateRange } from '@components/MapView/DateRange';
import { ScanDeviceSelect } from '@components/MapView/ScanDevicesSelect';
import { filterPhotos, getNamesOfSelectedDevices } from '@components/MapView/helper';
import { useProjectMetadata } from '@hooks/useProjectsMetadata';
import { filterState, setFromDate, setToDate } from '@state/Filter';
import { useProjectData } from '@state/ProjectData';
import { downloadZip } from './PhotosView';
import { getColumnDefinitions } from './getColumnDefinitions';
import { getRowId } from './tableCellRenderers';

// Fix for getting space key to work in inputs in the data grid
const onCellKeyDown: GridEventListener<'cellKeyDown'> = (_, events) => {
  if (events.code === 'Space') {
    events.stopPropagation();
  }
};

export const DesktopPhotosView: React.FC = () => {
  const { fromDate, toDate, visiblePhotoCategory, scanDevices } = useSnapshot(filterState);
  const { selectedProject } = useProjectMetadata();
  const [search, setSearch] = useState('');
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const photos = useProjectData((state) => state.photos);
  const selectedScanDevices = getNamesOfSelectedDevices(scanDevices);
  const patchPhoto = useProjectData((p) => p.patchPhoto);

  const filteredPhotos = useMemo(
    () =>
      filterPhotos(
        photos,
        visiblePhotoCategory,
        fromDate,
        toDate,
        selectedScanDevices,
        search,
      ).sort((p1, p2) => (p1.recordedDate < p2.recordedDate ? 1 : -1)),
    [visiblePhotoCategory, search, photos, fromDate, toDate, selectedScanDevices],
  );

  return (
    <Box component="div" sx={{ padding: '1rem', marginTop: 9 }}>
      <Paper
        elevation={1}
        sx={{
          display: 'flex',
          marginBottom: '1rem',
          padding: '1.25rem',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <Box component="div" sx={{ width: '25%' }}>
          <DateRange
            fromDate={fromDate}
            setFromDate={setFromDate}
            setToDate={setToDate}
            toDate={toDate}
          />
        </Box>
        <CategorySelect />
        <ScanDeviceSelect />
        <TextField
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() => setSearch('')}
                sx={{ display: search ? 'visible' : 'none' }}
              >
                <Clear />
              </IconButton>
            ),
          }}
          label="Search"
          onInput={(e) => setSearch((e.target as HTMLInputElement).value)}
          placeholder="search"
          sx={{ flexGrow: 1 }}
          value={search}
        />
        <Button
          color="primary"
          data-testid="Download"
          disabled={isDownloading || filteredPhotos.length === 0}
          onClick={() => downloadZip(filteredPhotos, setIsDownloading, selectedProject)}
          size="medium"
          sx={{
            height: 48,
          }}
          variant="contained"
        >
          {!isDownloading ? (
            <>
              <FileDownloadOutlined sx={{ paddingRight: 1 }} />
              <Typography>Download ({filteredPhotos.length})</Typography>
            </>
          ) : (
            <CenteredLoadingSpinner />
          )}
        </Button>
      </Paper>
      <Paper elevation={1} sx={{ height: (theme) => `calc(100vh - ${theme.spacing(36)})` }}>
        <DataGrid
          columns={getColumnDefinitions(patchPhoto)}
          disableRowSelectionOnClick
          getRowId={getRowId}
          onCellKeyDown={onCellKeyDown}
          rowHeight={72}
          rows={filteredPhotos}
        />
      </Paper>
    </Box>
  );
};
