import { Grid, TextField } from '@mui/material';
import { DatePicker as MuiDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import type { DateTime } from 'luxon';
import React, { type FC } from 'react';

interface DatePickerProps {
  label: string;
  dateTime: DateTime;
  setDateTime: (date: DateTime) => void;
}

interface DateRangeProps {
  flexGap?: string;
  fromDate: DateTime;
  toDate: DateTime;
  setFromDate: (date: DateTime) => void;
  setToDate: (date: DateTime) => void;
}

const DatePicker: FC<DatePickerProps> = ({ label, dateTime, setDateTime }) => (
  <MuiDatePicker
    inputFormat="dd/MM/yyyy"
    label={label}
    onChange={(date) => {
      if (!date) return;
      setDateTime(date);
    }}
    renderInput={(params) => <TextField {...params} style={{ flex: 1 }} />}
    value={dateTime}
  />
);

export const DateRange: FC<DateRangeProps> = ({
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  flexGap,
}) => (
  <Grid container direction="row" gap={flexGap ?? '5px'}>
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <DatePicker dateTime={fromDate} label="From" setDateTime={setFromDate} />
      <DatePicker dateTime={toDate} label="To" setDateTime={setToDate} />
    </LocalizationProvider>
  </Grid>
);
