import { Alert, AlertColor } from '@mui/material';
import Box from '@mui/material/Box';
import React, { FC } from 'react';
import { proxy, useSnapshot } from 'valtio';

export const boxState = proxy<{ message: string; variant: AlertColor; open: boolean }>({
  variant: 'error',
  open: false,
  message: '',
});

export const alertBox = (content: string): void => {
  boxState.open = true;
  boxState.message = content;
  boxState.variant = 'error';
};

export const successBox = (content: string, autoHideDuration?: number): void => {
  boxState.open = true;
  boxState.message = content;
  boxState.variant = 'success';
  if (autoHideDuration) {
    setTimeout(closeMessageBox, autoHideDuration);
  }
};

const closeMessageBox = () => {
  boxState.open = false;
  boxState.message = '';
};

export const MessageBox: FC = () => {
  const { open, message, variant } = useSnapshot(boxState);

  if (!open) return null;

  return (
    <Box
      component="div"
      sx={(theme) => ({
        position: 'fixed',
        padding: '2em',
        zIndex: theme.zIndex.snackbar,
        bottom: 0,
        width: '100%',
        boxSizing: 'border-box',
      })}
    >
      <Alert onClose={closeMessageBox} severity={variant} variant="filled">
        {message}
      </Alert>
    </Box>
  );
};
