import { Box, Chip, Table, TableBody, TableRow } from '@mui/material';
import { DateTime } from 'luxon';
import * as React from 'react';
import { TableCell } from '@components/Common/BorderlessTableCell';
import { PhotoAnnotation } from '@components/Common/PhotoAnnotation';
import type { PreviewPhoto } from '@hooks/useMapboxInteractions';
import { useProjectData } from '@state/ProjectData';
import { toHumanReadable } from '@utils';

export const PreviewPhotoDetails: React.FC<{
  previewPhoto?: PreviewPhoto;
}> = ({ previewPhoto }) => {
  const patchPhoto = useProjectData((p) => p.patchPhoto);

  if (!previewPhoto) return null;

  const {
    address,
    comment,
    recordedDate,
    scanDeviceName,
    category,
    annotation,
    id,
    projectId,
    color,
    geoPositionSolution,
  } = previewPhoto;

  return (
    <>
      <Table size="small">
        <TableBody>
          {geoPositionSolution && (
            <TableRow>
              <TableCell>Accuracy:</TableCell>
              <TableCell>
                <Chip
                  label={toHumanReadable(geoPositionSolution)}
                  size="small"
                  sx={{ background: color }}
                />
              </TableCell>
            </TableRow>
          )}
          {address && (
            <TableRow>
              <TableCell>Address:</TableCell>
              <TableCell>{address}</TableCell>
            </TableRow>
          )}
          {recordedDate && (
            <TableRow>
              <TableCell>Date:</TableCell>
              <TableCell>
                {DateTime.fromISO(recordedDate).setLocale('en-gb').toLocaleString()}
              </TableCell>
            </TableRow>
          )}
          {comment && (
            <TableRow>
              <TableCell>Comment:</TableCell>
              <TableCell>{comment}</TableCell>
            </TableRow>
          )}
          {scanDeviceName && (
            <TableRow>
              <TableCell>Device:</TableCell>
              <TableCell>{scanDeviceName}</TableCell>
            </TableRow>
          )}
          {category && (
            <TableRow>
              <TableCell>Category:</TableCell>
              <TableCell>{category}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell colSpan={2}>
              <Box alignItems="center" component="div" display="flex" marginBottom="0.5rem">
                Annotation:
              </Box>
              <PhotoAnnotation
                key={id}
                patchPhoto={patchPhoto(projectId, id)}
                photoAnnotation={annotation}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};
