import { MapOutline, MapFilled, PhotoOutline, PhotoFilled, ArrowBoxOut } from '@deepup/icons';
import { useDeepUpTheme } from '@deepup/mui-theme-deepup';
import { Stack, Tab, Tabs, Button, styled, useMediaQuery, Tooltip } from '@mui/material';
import React, { SVGProps, JSX } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEnvironment } from '@hooks/useEnvironment';
import { useProjectUserPermissions } from '@hooks/useProjectUserPermissions';
import { useProjectMetadata } from '@hooks/useProjectsMetadata';

const Wrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 56,
  zIndex: theme.zIndex.drawer + 10,

  [theme.breakpoints.up('sm')]: {
    top: 80,
  },
}));

const NavigationBarContainer = styled(Stack)(({ theme }) => ({
  position: 'relative',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  paddingRight: theme.spacing(1),
  width: '100vw',
  backgroundColor:
    theme.palette.mode === 'light' ? theme.palette.background.paper : theme.palette.grey[100],
  borderTop: `solid 1px ${theme.palette.divider}`,
  overflowX: 'auto',

  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const Shadow = styled('div')(({ theme }) => ({
  height: 10,
  marginTop: -10,
  boxShadow: theme.shadows[6],
}));

export const NavigationBar = () => {
  const { cssUrl } = useEnvironment();
  const navigate = useNavigate();
  const { theme } = useDeepUpTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { selectedProject } = useProjectMetadata();
  const { documentation: hasDocumentationPermission } = useProjectUserPermissions();
  const location = useLocation();
  const activeProjectPage = location.pathname.split('/')[2];
  const activeTabIndex = activeProjectPage === 'map' ? 0 : 1;

  if (!selectedProject?.id) {
    return null;
  }

  const handleClickTab = (page: 'map' | 'photos') => () => {
    navigate(`/${selectedProject.id}/${page}`);
  };

  const getTabIcon = (
    index: number,
    iconOutline: (props: SVGProps<SVGSVGElement>) => JSX.Element,
    iconFilled: (props: SVGProps<SVGSVGElement>) => JSX.Element,
  ) => {
    if (isMobile) return undefined;

    const IconElement = index === activeTabIndex ? iconFilled : iconOutline;

    return <IconElement fill="currentColor" height="24px" width="24px" />;
  };

  const documentationButton = (
    <Button
      color="secondary"
      component="a"
      disabled={!hasDocumentationPermission}
      href={`${cssUrl}/projects/${selectedProject.id}`}
      startIcon={<ArrowBoxOut fill="currentColor" height="24px" width="24px" />}
      sx={{ flexShrink: 0 }}
      target="_blank"
      variant="text"
    >
      {isMobile ? 'Documentation' : 'Documentation (CSS)'}
    </Button>
  );

  const wrappedDocumentationButton = hasDocumentationPermission ? (
    documentationButton
  ) : (
    <Tooltip title="You are not authorized to access 'Documentation (CSS)'. Please contact your administrator if you require access.">
      <span>{documentationButton}</span>
    </Tooltip>
  );

  return (
    <Wrapper>
      <NavigationBarContainer>
        <Tabs sx={{ flexShrink: 0 }} value={activeTabIndex}>
          <Tab
            icon={getTabIcon(0, MapOutline, MapFilled)}
            iconPosition="start"
            label="Map"
            onClick={handleClickTab('map')}
          />
          <Tab
            icon={getTabIcon(1, PhotoOutline, PhotoFilled)}
            iconPosition="start"
            label="Photos"
            onClick={handleClickTab('photos')}
            sx={{
              button: {
                fontWeight: 'bold',
              },
            }}
          />
        </Tabs>
        {wrappedDocumentationButton}
      </NavigationBarContainer>
      <Shadow />
    </Wrapper>
  );
};
