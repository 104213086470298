import type { CircleLayer } from 'mapbox-gl';

import { PhotoColor, TopdownViewColor } from '@components/MapView/constants';

export const unclusteredPointLayer: CircleLayer = {
  id: 'unclustered-point',
  type: 'circle' as const,
  source: 'photos',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': [
      'case',
      ['==', ['get', 'type'], 'PHOTO'],
      [
        'case',
        ['==', ['get', 'geoPositionSolution'], 'CORELOCATION'],
        PhotoColor.CoreLocation,
        PhotoColor.Default,
      ],
      '#fff',
    ],
    'circle-opacity': ['case', ['==', ['get', 'type'], 'PHOTO'], 1, 1],
    'circle-radius': ['case', ['==', ['get', 'type'], 'PHOTO'], 12, 8],
    'circle-stroke-width': ['case', ['==', ['get', 'type'], 'PHOTO'], 1, 4],
    'circle-stroke-color': [
      'case',
      ['==', ['get', 'type'], 'PHOTO'],
      '#fff',
      TopdownViewColor.Default,
    ],
  },
};

export const clusterLayer: CircleLayer = {
  id: 'clusters',
  type: 'circle' as const,
  source: 'photos',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': PhotoColor.Cluster,
    'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
  },
};

export const clusterCountLayer = {
  id: 'cluster-count',
  type: 'symbol' as const,
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12,
  },
};
