import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { request } from '@api';
import { appendAvailableScanDevices, setPhotoCategories } from '@state/Filter';
import { useProjectData } from '@state/ProjectData';
import type { Photo } from '@types';
import { addTimestampAttributeToScanDevices } from './helper';

interface UseGeoJson {
  photos: Photo[];
}

export const usePhotos = (): UseGeoJson => {
  const { projectId } = useParams<{ projectId: string }>();
  const { photos, setPhotos } = useProjectData(({ photos, setPhotos }) => ({ photos, setPhotos }));

  useEffect(() => {
    request<Photo[]>('GET', { path: `/projects/${projectId}/photos` }).then((photos) => {
      setPhotos(photos);
      setPhotoCategories([
        ...new Set(
          photos
            .filter((photo) => photo.category)
            .map(
              (photo) =>
                (
                  photo.category as {
                    name: string;
                  }
                ).name,
            ),
        ),
      ]);

      const scanEvents = photos.map((p) => ({
        scanDeviceName: p.scanDeviceName,
        timestamp: DateTime.fromISO(p.recordedDate).toMillis(),
      }));
      const allScanDevices = addTimestampAttributeToScanDevices(scanEvents);

      appendAvailableScanDevices(allScanDevices);
    });
  }, [projectId, setPhotos]);

  return { photos };
};
