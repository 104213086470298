import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { locationChanged } from '../analytics';

import { ClarificationCaseView } from './ClarificationCaseView';
import { ProjectView } from './ProjectView';
import { ProjectsView } from './ProjectsView';

export const AppRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    locationChanged(location.pathname);
  }, [location.pathname]);

  return (
    <Routes>
      <Route element={<ProjectView />} path="/:projectId/*" />
      <Route element={<ClarificationCaseView />} path="/clarification-case" />
      <Route element={<ProjectsView />} path="/" />
      <Route element={<Navigate replace to="/" />} path="*" />
    </Routes>
  );
};
