import React, { type FC } from 'react';
import { Layer, Source } from 'react-map-gl';
import { clusterSourceConfig } from '@components/MapView/constants';
import { mergeFeatureCollections } from '@components/MapView/helper';
import { clusterCountLayer, clusterLayer, unclusteredPointLayer } from '@components/MapView/index';
import { usePhotosGeoJson } from '@hooks/usePhotosGeoJson';
import { useTopDownViewsGeoJson } from '@hooks/useTopDownViewsGeoJson';

export const PhotoLayer: FC = React.memo(function PhotoLayer() {
  const photosGeojson = usePhotosGeoJson();
  const topdownViewsGeojson = useTopDownViewsGeoJson();
  const data = mergeFeatureCollections(photosGeojson, topdownViewsGeojson);

  return (
    <Source
      cluster={true}
      clusterMaxZoom={clusterSourceConfig.clusterMaxZoom}
      clusterRadius={clusterSourceConfig.clusterRadius}
      data={data}
      id="photos"
      maxzoom={clusterSourceConfig.maxzoom}
      type="geojson"
    >
      <Layer {...clusterLayer} />
      <Layer {...clusterCountLayer} />
      <Layer {...unclusteredPointLayer} />
    </Source>
  );
});
