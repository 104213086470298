import type { LngLatBoundsLike } from 'mapbox-gl';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSnapshot, proxy } from 'valtio';
import { request } from '@api';
import { alertBox } from '@components/MessageBox';

export interface ProjectMetadata {
  id: string;
  name: string;
  initialViewport: LngLatBoundsLike | null;
}

const projectMetadata = proxy<{
  selectedProject: ProjectMetadata | null;
  allProjects: ProjectMetadata[];
}>({ selectedProject: null, allProjects: [] });

const setAllProjects = (allProjects: ProjectMetadata[]) => {
  projectMetadata.allProjects = allProjects;
};

export const setSelectedProject = (project: ProjectMetadata | null) => {
  projectMetadata.selectedProject = project;
};

export const useProjectMetadata = () => {
  const { selectedProject, allProjects } = useSnapshot(projectMetadata);
  const { projectId } = useParams<{ projectId: string }>();

  const fetchProjectMetadata = async (projectId: string) => {
    const response = await request<ProjectMetadata>('GET', {
      path: `/projects/${projectId}`,
    });

    if (response) {
      setSelectedProject(response);
    } else {
      alertBox('No project found.');
    }
  };

  useEffect(() => {
    if (selectedProject?.id === projectId || !projectId) return;

    const project = allProjects.find(({ id }) => id === projectId);

    if (project) {
      setSelectedProject(project as ProjectMetadata);

      return;
    }
    fetchProjectMetadata(projectId);
  }, [projectId, selectedProject, allProjects]);

  return { selectedProject, allProjects, setSelectedProject };
};

export const useAllProjectsMetadata = () => {
  const { allProjects } = useSnapshot(projectMetadata);

  const fetchAllProjectsMetadata = async () => {
    const { content } = await request<{ content: ProjectMetadata[] }>('GET', {
      path: '/projects?size=1000',
    });

    if (content) {
      setAllProjects(content);
    } else alertBox('No projects found.');
  };

  useEffect(() => {
    fetchAllProjectsMetadata();
  }, []);

  return { allProjects };
};
