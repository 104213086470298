import {
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import type { FC } from 'react';
import React from 'react';
import { useSnapshot } from 'valtio';
import {
  filterState,
  ScanDeviceAttributes,
  setScanDevicesVisibility,
  toggleScanDeviceVisibility,
} from '@state/Filter';
import { getNamesOfSelectedDevices } from './helper';

const noOpValue = [''];

type SelectableScanDevice = ScanDeviceAttributes & { name: string; isDisabled: boolean };

export const ScanDeviceSelect: FC = () => {
  const { scanDevices, fromDate, toDate } = useSnapshot(filterState);
  const selectedScanDevices = getNamesOfSelectedDevices(scanDevices);
  const areAllSelected = selectedScanDevices.length === Object.keys(scanDevices).length;

  const isDeviceDisabled = (scanAttributes: ScanDeviceAttributes) =>
    (scanAttributes.minTimestamp < fromDate.startOf('day').toMillis() &&
      scanAttributes.maxTimestamp < fromDate.startOf('day').toMillis()) ||
    (scanAttributes.minTimestamp > toDate.startOf('day').toMillis() &&
      scanAttributes.maxTimestamp > toDate.startOf('day').toMillis());

  const selectableScandevices: SelectableScanDevice[] = Object.entries(scanDevices).map(
    ([key, attributes]) => ({
      name: key,
      isDisabled: isDeviceDisabled(attributes),
      ...attributes,
    }),
  );

  const availableScanDevicesCount = selectableScandevices.filter((d) => !d.isDisabled).length;

  return (
    <FormControl sx={{ flex: 1, minWidth: '10px', width: '100%' }}>
      <InputLabel id="scan-devices-select">Scan Devices</InputLabel>
      <Select
        label="Scan Devices"
        labelId="scan-devices-select"
        multiple
        renderValue={() => (areAllSelected ? 'All' : `${selectedScanDevices.length} selected`)}
        value={noOpValue}
      >
        <MenuItem onClick={() => setScanDevicesVisibility(!areAllSelected)} value="ALL">
          <Checkbox
            checked={areAllSelected}
            indeterminate={!areAllSelected && !!selectedScanDevices.length}
          />
          <ListItemText
            primary={areAllSelected ? 'Deselect All' : 'All'}
            secondary={`${availableScanDevicesCount} available for period`}
          />
        </MenuItem>
        <Divider variant="middle" />
        {selectableScandevices.map((device) => (
          <MenuItem
            disabled={device.isDisabled}
            key={device.name}
            onClick={() => toggleScanDeviceVisibility(device.name, !device.isVisible)}
            value={device.name}
          >
            <Checkbox checked={device.isVisible && !device.isDisabled} />
            <ListItemText primary={device.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
