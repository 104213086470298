import { Table, TableBody, TableRow } from '@mui/material';
import * as React from 'react';
import { TableCell } from '@components/Common/BorderlessTableCell';
import type { PreviewTopdownView } from '@hooks/useMapboxInteractions';
import { formatDate, toMeterString } from '@utils';

export const PreviewTopdownViewDetails: React.FC<{
  preview?: PreviewTopdownView;
}> = ({ preview }) => {
  if (!preview) return null;

  const { beginDate, endDate, device, length } = preview;

  return (
    <Table size="small">
      <TableBody>
        {beginDate && (
          <TableRow>
            <TableCell>Scan Begin:</TableCell>
            <TableCell>{formatDate(beginDate)}</TableCell>
          </TableRow>
        )}
        {endDate && (
          <TableRow>
            <TableCell>Scan End:</TableCell>
            <TableCell>{formatDate(endDate)}</TableCell>
          </TableRow>
        )}
        {device && (
          <TableRow>
            <TableCell>Device:</TableCell>
            <TableCell>{device.name}</TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell>Scan Length:</TableCell>
          <TableCell>
            {typeof length === 'number' ? toMeterString(length) : 'Not yet available'}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
