import { DateTime } from 'luxon';
import { proxy } from 'valtio';
import {
  ElementTypeUsage,
  elementTypeUsages,
  LayingType,
  layingTypes,
  SurfaceType,
  surfaceTypes,
} from '@types';

import {
  trackFilterDateChanged,
  trackPhotoCategoryChanged,
  trackShowTrenchTopdownViews,
} from '../analytics';

const defaultToDate = DateTime.now();
const defaultFromDate = defaultToDate.minus({ days: 10 });

type VisibilityMap<T extends string> = Record<T, boolean>;

export type ScanDeviceAttributes = {
  isVisible: boolean;
  minTimestamp: number;
  maxTimestamp: number;
};

const arrToBooleanRecord = <T extends string>(acc: Record<T, boolean>, type: T) => ({
  ...acc,
  [type]: true,
});

const initialVisibilities = {
  usage: elementTypeUsages.reduce(arrToBooleanRecord, {} as VisibilityMap<ElementTypeUsage>),
  surface: surfaceTypes.reduce(arrToBooleanRecord, {} as VisibilityMap<SurfaceType>),
  laying: layingTypes.reduce(arrToBooleanRecord, {} as VisibilityMap<LayingType>),
};

export type Visibilities = 'usage' | 'surface' | 'laying';

export const defaultFilterState = {
  fromDate: defaultFromDate,
  toDate: defaultToDate,
  showPhotos: false,
  showTopdownViews: false,
  visibleMapFeatures: initialVisibilities,
  selectedVisibilityTab: 'usage' as Visibilities,
  visiblePhotoCategory: 'ALL',
  photoCategories: ['ALL'],
  scanDevices: {},
};

export const filterState = proxy<{
  fromDate: DateTime;
  toDate: DateTime;
  showPhotos: boolean;
  showTopdownViews: boolean;
  photoCategories: string[];
  visiblePhotoCategory: string;
  visibleMapFeatures: {
    usage: Record<ElementTypeUsage, boolean>;
    surface: Record<SurfaceType, boolean>;
    laying: Record<LayingType, boolean>;
  };
  scanDevices: Record<string, ScanDeviceAttributes>;
  selectedVisibilityTab: Visibilities;
}>(defaultFilterState);

export const toggleScanDeviceVisibility = (scanDevice: string, isVisible: boolean) => {
  filterState.scanDevices[scanDevice].isVisible = isVisible;
};

export const setScanDevicesVisibility = (visible: boolean) => {
  filterState.scanDevices = Object.keys(filterState.scanDevices).reduce(
    (acc, current) => ({
      ...acc,
      [current]: { ...filterState.scanDevices[current], isVisible: visible },
    }),
    {},
  );
};

export const setVisiblePhotoCategory = (category: string) => {
  filterState.visiblePhotoCategory = category;
  trackPhotoCategoryChanged(category);
};

export const setPhotoCategories = (categories: string[]) => {
  filterState.photoCategories = ['ALL', ...categories.sort()];
};

export const setFromDate = (dateTime: DateTime) => {
  filterState.fromDate = dateTime;
  trackFilterDateChanged();
};

export const setToDate = (dateTime: DateTime) => {
  filterState.toDate = dateTime;
  trackFilterDateChanged();
};

export const setShowPhotos = (showPhotos: boolean) => {
  filterState.showPhotos = showPhotos;
};

export const togglePhotos = () => {
  filterState.showPhotos = !filterState.showPhotos;
};

export const toggleShowTopdownViews = () => {
  filterState.showTopdownViews = !filterState.showTopdownViews;

  // Only track the event if the user has just enabled the topdown views
  if (filterState.showTopdownViews) {
    trackShowTrenchTopdownViews();
  }
};

export const setAvailableSurfaceTypes = (surfaceTypes: SurfaceType[]) => {
  filterState.visibleMapFeatures.surface = surfaceTypes.reduce(
    arrToBooleanRecord,
    {} as VisibilityMap<SurfaceType>,
  );
};

const getMinTimestamp = (
  newScanDevice: ScanDeviceAttributes,
  currentScanDevice?: ScanDeviceAttributes,
): number =>
  currentScanDevice && currentScanDevice.minTimestamp < newScanDevice.minTimestamp
    ? currentScanDevice.minTimestamp
    : newScanDevice.minTimestamp;

const getMaxTimestamp = (
  newScanDevice: ScanDeviceAttributes,
  currentScanDevice?: ScanDeviceAttributes,
): number =>
  currentScanDevice && currentScanDevice.maxTimestamp > newScanDevice.maxTimestamp
    ? currentScanDevice.maxTimestamp
    : newScanDevice.maxTimestamp;

const getNewDevicesWithAbsoluteTimestamps = (
  scanDevices: Record<string, ScanDeviceAttributes>,
): Record<string, ScanDeviceAttributes> =>
  Object.entries(scanDevices)
    .map(([scanDeviceName, attributes]) => ({
      name: scanDeviceName,
      attributes: {
        isVisible:
          filterState.scanDevices[scanDeviceName]?.isVisible ?? allScanDevicesAreSelected(),
        minTimestamp: getMinTimestamp(attributes, filterState.scanDevices[scanDeviceName]),
        maxTimestamp: getMaxTimestamp(attributes, filterState.scanDevices[scanDeviceName]),
      },
    }))
    .reduce<Record<string, ScanDeviceAttributes>>(
      (acc, current) => ({
        ...acc,
        [current.name]: current.attributes,
      }),
      {},
    );

export const allScanDevicesAreSelected = () =>
  Object.entries(filterState.scanDevices).every(([, attributes]) => attributes.isVisible);

export const appendAvailableScanDevices = (scanDevices: Record<string, ScanDeviceAttributes>) => {
  // Photos and geojson can have the same device with different timestamps, we take always the minimum and maximum
  const newDevices = getNewDevicesWithAbsoluteTimestamps(scanDevices);

  filterState.scanDevices = { ...filterState.scanDevices, ...newDevices };
};

export const resetAvailableScanDevices = () => {
  filterState.scanDevices = {};
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const toggleVisibility: Record<Visibilities, (type: any) => void> = {
  usage: (usage: ElementTypeUsage) => {
    filterState.visibleMapFeatures.usage[usage] = !filterState.visibleMapFeatures.usage[usage];
  },
  surface: (surface: SurfaceType) => {
    filterState.visibleMapFeatures.surface[surface] =
      !filterState.visibleMapFeatures.surface[surface];
  },
  laying: (laying: LayingType) => {
    filterState.visibleMapFeatures.laying[laying] = !filterState.visibleMapFeatures.laying[laying];
  },
};

export const setSelectedVisibilityTab = (tab: Visibilities) => {
  filterState.selectedVisibilityTab = tab;
};
