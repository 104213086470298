import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import React from 'react';
import type { FC } from 'react';

export const FloatingCloseBtn: FC<{ onClick: () => void }> = ({ onClick }) => (
  <Box
    component="div"
    onClick={onClick}
    sx={(theme) => ({
      position: 'fixed',
      right: 0,
      zIndex: 1,
      padding: 1,
      background: theme.palette.background.paper,
      borderRadius: '100%',
      margin: theme.spacing(1),
      boxShadow: theme.shadows[4],
      height: theme.spacing(5),
      width: theme.spacing(5),
    })}
  >
    <CloseIcon cursor="pointer" fontSize="medium" />
  </Box>
);
