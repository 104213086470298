import type { ScanDeviceAttributes } from '@state/Filter';

export type ScanEvent = {
  scanDeviceName: string;
  timestamp: number;
};

export const addTimestampAttributeToScanDevices = (
  scanEvents: ScanEvent[],
): Record<string, ScanDeviceAttributes> =>
  scanEvents.reduce((acc: Record<string, ScanDeviceAttributes>, current) => {
    const scanDeviceName = current.scanDeviceName;
    const scanDevice = acc[scanDeviceName];
    const timestampFromEvent = current.timestamp;
    const maxTimestampFromScanDevice = scanDevice ? scanDevice.maxTimestamp : timestampFromEvent;
    const minTimestampFromScanDevice = scanDevice ? scanDevice.minTimestamp : timestampFromEvent;

    return {
      ...acc,
      [scanDeviceName]: {
        isVisible: true,
        maxTimestamp:
          timestampFromEvent > maxTimestampFromScanDevice
            ? timestampFromEvent
            : maxTimestampFromScanDevice,
        minTimestamp:
          timestampFromEvent < minTimestampFromScanDevice
            ? timestampFromEvent
            : minTimestampFromScanDevice,
      },
    };
  }, {});
