import { useMemo } from 'react';
import { useSnapshot } from 'valtio';
import {
  filterPhotos,
  getNamesOfSelectedDevices,
  mapPhotosToGeoJson,
} from '@components/MapView/helper';
import { filterState } from '@state/Filter';
import { useProjectData } from '@state/ProjectData';
import type { PhotoGeoJson } from '@types';

export const usePhotosGeoJson = () => {
  const { showPhotos, fromDate, toDate, visiblePhotoCategory, scanDevices } =
    useSnapshot(filterState);
  const { photos } = useProjectData(({ photos }) => ({
    photos,
  }));

  const photosGeojson: PhotoGeoJson | undefined = useMemo(() => {
    if (!showPhotos) return undefined;

    const selectedScanDevices = getNamesOfSelectedDevices(scanDevices);
    const filteredPhotos = filterPhotos(
      photos,
      visiblePhotoCategory,
      fromDate,
      toDate,
      selectedScanDevices,
    );

    return mapPhotosToGeoJson(filteredPhotos);
  }, [showPhotos, photos, visiblePhotoCategory, fromDate, toDate, scanDevices]);

  return photosGeojson;
};
