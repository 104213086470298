export type Environment = {
  apiUrl: string;
  auth0Audience: string;
  auth0ClientId: string;
  auth0Domain: string;
  auth0Scope: string;
  cssUrl: string;
  enableProductionProfiling: boolean;
  mapboxAccessToken: string;
  analyticsMessId: string;
  isDev: boolean;
};

export const useEnvironment: () => Environment = () => {
  return {
    apiUrl: import.meta.env?.VITE_API_URL,
    cssUrl: import.meta.env?.VITE_CSS_URL,
    mapboxAccessToken: import.meta.env?.VITE_MAPBOX_ACCESS_TOKEN,
    auth0Audience: import.meta.env?.VITE_AUTH0_AUDIENCE,
    auth0ClientId: import.meta.env?.VITE_AUTH0_CLIENT_ID,
    auth0Domain: import.meta.env?.VITE_AUTH0_DOMAIN,
    auth0Scope: import.meta.env?.VITE_AUTH0_SCOPE,
    enableProductionProfiling: import.meta.env?.VITE_ENABLE_PRODUCTION_PROFILING === 'true',
    analyticsMessId: import.meta.env?.VITE_ANALYTICS_MESS_ID,
    isDev: import.meta.env?.DEV,
  };
};
